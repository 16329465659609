import { toast } from "react-toastify";
import {
  seasonsList,
  seasonCreate,
  trailerDelete,
  seasonEdit,
} from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getSeasons =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    let seasonsById = { ...entities.seasonsById };

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    seasonsList(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { seasons } = response;

        if (seasons) {
          seasonsById[titleId] = seasons;
          dispatch(setKeyValue("seasonsById", seasonsById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => dispatch(setKeyValue("loading", false)));
  };

export const addSeason =
  (titleId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    seasonCreate(titleId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { season } = response;

        if (season) {
          window.location.reload();
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible d'ajouter votre saison", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editSeason =
  (seasonId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    seasonEdit(seasonId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { season } = response;

        if (season) {
          toast("Modifications enregistrées", {
            position: "top-center",
            type: "success",
          });

          setTimeout(() => window.location.reload(), 1500);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer les modifications", {
          position: "top-center",
          type: "error",
        });
      });
  };
export const deleteTrailer =
  (seasonId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    trailerDelete(seasonId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { deletedAt } = response;

        if (deletedAt) {
          toast("Vidéo supprimée", {
            position: "top-center",
            type: "success",
          });

          setTimeout(() => window.location.reload(), 1500);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer votre vidéo", {
          position: "top-center",
          type: "error",
        });
      });
  };
