import React, { useCallback, useState } from "react";
import {
  LinkIcon,
  TvIcon,
  MagnifyingGlassIcon,
  ArrowTopRightOnSquareIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  TextInput,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Select,
  SelectItem,
} from "@tremor/react";
import {
  durationToSeconds,
  youtubeEmbedUrl,
  youtubeId,
} from "../../utils/LinkUtil";
import { youtubeDetails } from "../../api/assetService";
import { toast } from "react-toastify";

// yt frame : https://www.youtube.com/embed/G4o2o6gPJUc
// vimeo frame : https://player.vimeo.com/video/169599296

const LinkUploader = ({ onLinked }) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [platform, setPlatform] = useState("youtube");

  const onButtonHandler = useCallback(() => {
    if (url == null) return;

    if (platform === "youtube") {
      setLoading(true);
      youtubeDetails({ videoId: youtubeId(url) })
        .then((result) => {
          const resp = result.data;
          const {
            data: { contentDetails },
          } = resp;

          if (contentDetails) {
            const duration = durationToSeconds(contentDetails.duration);
            setEmbedUrl(youtubeEmbedUrl(url));
            onLinked({
              assetUrl: url,
              assetUri: youtubeEmbedUrl(url),
              assetPlatform: platform,
              videoDuration: duration,
            });
          } else {
            throw new Error("Error occured");
          }
        })
        .catch((err) => {
          toast("Impossible de récupérer les infos de la vidéo", {
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setEmbedUrl(url);
      onLinked({
        assetUrl: url,
        assetUri: youtubeEmbedUrl(url),
        assetPlatform: platform,
      });
    }
  }, [platform, url, onLinked]);

  return (
    <>
      <div>
        <TabGroup>
          <TabList variant="solid">
            <Tab
              icon={ArrowTopRightOnSquareIcon}
              className="p-2 px-5"
              onClick={() => setPlatform("youtube")}
            >
              EXTERNAL
            </Tab>
            <Tab
              icon={TvIcon}
              className="p-2 px-5"
              onClick={() => setPlatform(null)}
            >
              STREAM
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="py-4 space-y-3">
              <div className="md:w-[20rem]">
                <Select
                  defaultValue={platform}
                  placeholder="Plateforme"
                  onChange={(v) => setPlatform(v)}
                >
                  <SelectItem value="youtube" icon={VideoCameraIcon}>
                    Youtube
                  </SelectItem>
                </Select>
              </div>

              <div className="flex gap-2 w-full items-center justify-center">
                <TextInput
                  icon={LinkIcon}
                  placeholder={
                    "Ex: https://www.youtube.com/watch?v=G4o2o6gPJUc"
                  }
                  onChange={(evt) => setUrl(evt.target.value)}
                />
                <Button
                  loading={loading}
                  icon={MagnifyingGlassIcon}
                  onClick={onButtonHandler}
                >
                  Rechercher la vidéo
                </Button>
              </div>
            </TabPanel>
            <TabPanel className="pb-4" />
          </TabPanels>
        </TabGroup>

        {embedUrl && (
          <iframe
            src={embedUrl}
            title="Video preview"
            className="h-[25rem] w-full bg-gray-200 my-5"
          />
        )}
      </div>
    </>
  );
};

export default LinkUploader;
