/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import LogoApp from "../../../assets/img/logos/logo-main.png";
import { Button } from "@tremor/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

class AuthLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountPassword: "",
      accountUsername: "",
    };
  }

  componentDidMount() {
    document.title = "Connexion - Administrateur";
  }

  onSubmit = () => {
    const { accountUsername, accountPassword } = this.state;

    if (accountUsername !== "admin01" || accountPassword !== "P@ss456$_") {
      toast("Les identifiants sont invalides !", {
        type: "error",
        position: "bottom-center",
      });
      return;
    } else {
      this.props.history.push("/dashboard");
    }
  };

  render() {
    const { accountUsername, loading } = this.props;
    return (
      <div className="bg-[#F6F7FF]">
        <section className="flex flex-col justify-center items-center">
          <div className="md:w-[50%] flex flex-col items-center justify-center py-10 px-6 lg:px-8">
            <div className="px-10 md:px-10 py-10 pb-16 shadow-md bg-white mx-auto max-w-xl md:max-w-xl">
              <img
                src={LogoApp}
                alt=""
                className="h-14 self-center text-center"
              />
              <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
                Connectez-vous à Congarts TV
              </h1>
              <p className="text-gray-500 mt-5 mb-5">
                Utilisez vos identifiants pour vous connecter
              </p>
              <div className="mt-6 space-y-4">
                <div>
                  <label>Votre identifiant</label>
                  <input
                    placeholder="Votre identifiant pour vous connecter"
                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                    autofocus
                    autoComplete
                    required
                    value={accountUsername}
                    onChange={(e) => {
                      let accountUsername = e.target.value;
                      this.setState({ accountUsername });
                    }}
                  />
                </div>
                <div className="">
                  <label>Mot de passe</label>
                  <input
                    type="password"
                    placeholder="Votre mot de passe"
                    minLength={6}
                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500
          focus:bg-white focus:outline-none"
                    required
                    onChange={(e) => {
                      let accountPassword = e.target.value;
                      this.setState({ accountPassword });
                    }}
                  />
                </div>

                <div className="flex flex-col justify-center space-y-5">
                  <Button
                    variant="secondary"
                    icon={ArrowRightIcon}
                    iconPosition="right"
                    onClick={() => this.onSubmit()}
                  >
                    Se connecter
                  </Button>
                  {/**  
                  <Button
                    variant="light"
                    onClick={() => {}}
                  >
                    Mot de passe oublié ?
                  </Button>
                  */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default AuthLogin;
