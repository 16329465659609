import { toast } from "react-toastify";
import { movieCreate, movieDelete, moviesList } from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getMovies =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    let moviesById = { ...entities.moviesById };
    let movieById = { ...entities.movieById };

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    moviesList(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { movies, movie } = response;

        if (movies) {
          moviesById[titleId] = movies;
          movieById[titleId] = movie;

          dispatch(setKeyValue("movieById", movieById));
          dispatch(setKeyValue("moviesById", moviesById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossible de charger le contenu", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const addMovie =
  (titleId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    movieCreate(titleId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { movie } = response;

        if (movie) {
          window.location.replace(`/titles/${titleId}/movie`);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible d'enregistrer votre épisode", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const deleteMovie =
  (trailerId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    movieDelete(trailerId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { deletedAt } = response;

        if (deletedAt) {
          toast("Vidéo supprimée", {
            position: "top-center",
            type: "success",
          });

          setTimeout(() => window.location.reload(), 1500);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer votre vidéo", {
          position: "top-center",
          type: "error",
        });
      });
  };
