const titlesById = (state) => state.entities.titlesById || {};
const atworksById = (state) => state.entities.atworksById || {};
const trailersById = (state) => state.entities.trailersById || {};
const episodesById = (state) => state.entities.episodesById || {};
const episodeById = (state) => state.entities.episodeById || {};
const seasonsById = (state) => state.entities.seasonsById || {};
const movieById = (state) => state.entities.movieById || {};
const moviesById = (state) => state.entities.moviesById || {};

export const getTitleById = (state, itemId) => {
  let collection = titlesById(state);
  if (collection[itemId]) return collection[itemId];

  return null;
};

export const getAtworksById = (state, itemId) => {
  let collection = atworksById(state);
  if (collection[itemId]) return collection[itemId];

  return [];
};

export const getTrailersById = (state, itemId) => {
  let collection = trailersById(state);
  if (collection[itemId]) return collection[itemId];

  return [];
};

export const getEpisodesById = (state, itemId) => {
  let collection = episodesById(state);
  if (collection[itemId]) return collection[itemId];

  return [];
};

export const getEpisodeById = (state, itemId) => {
  let collection = episodeById(state);
  return collection[itemId] || null;
};

export const getSeasonsById = (state, itemId) => {
  let collection = seasonsById(state);
  if (collection[itemId]) return collection[itemId];

  return [];
};

export const getMoviesById = (state, itemId) => {
  let collection = moviesById(state);
  if (collection[itemId]) return collection[itemId];

  return [];
};

export const getMovieById = (state, itemId) => {
  let collection = movieById(state);
  return collection[itemId] || null;
};
