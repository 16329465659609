import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import {
  TrashIcon,
  CloudArrowUpIcon,
  PlayIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import TitleMenu from "../titles/sidemenu";
import DeleteConfirm from "../../components/feedback/deleteModal";
import Item from "./item";
import { formatTime } from "../../utils/NumberUtil";

class PageMovie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTrailerId: null,
      modalDeleteVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getMovies, getTitle, match, title } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getMovies) getMovies(match.params.id, {});
  }

  render() {
    const { modalDeleteVisible } = this.state;
    const { movies, movie, match, history, loading, title, loadingDelete } =
      this.props;
    const titleId = match.params.id;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={3} />
          <PageLayout title={"Votre film"}>
            <div className="py-10">
              <Button
                loading={loading}
                variant="secondary"
                icon={CloudArrowUpIcon}
                iconPosition="right"
                size="xs"
                onClick={() => {
                  history.push(`/titles/${titleId}/movie/upload`);
                }}
              >
                Télécharger une vidéo
              </Button>

              <TabGroup>
                <TabList className="mt-8">
                  <Tab icon={VideoCameraIcon}>Vidéo du film</Tab>
                  <Tab icon={CloudArrowUpIcon}>Téléchargements</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {movie && (
                      <div className="grid grid-cols-3 2xl:grid-cols-4 gap-5 my-10">
                        <Item
                          posterAlt={movie.title}
                          posterImage={movie.posterAtworks[0]}
                          duration={formatTime(movie.duration)}
                          createdAt={moment(movie.createdAt).format(
                            "DD/MMM HH:mm"
                          )}
                          onRequestDelete={() => {}}
                        />
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <div className="grid grid-cols-3 2xl:grid-cols-4 gap-5 my-10">
                      {movies.map((item, x) => (
                        <div className="shadow">
                          <div className="h-[10rem] relative">
                            <img
                              alt={item.title}
                              src={item.posterAtworks[0]}
                              className="w-full h-full object-cover"
                            />
                            <div className="absolute inset-0 flex flex-col justify-center items-center">
                              <div className="w-12 h-12 rounded-full bg-white flex flex-col justify-center items-center">
                                <PlayIcon className="w-5 h-5 text-black" />
                              </div>
                            </div>
                          </div>
                          <div className="p-4">
                            <div className="py-3">
                              <h1 className="text-sm text-gray-500">
                                {formatTime(item.duration)}
                              </h1>
                              <h1 className="text-sm text-gray-500">
                                Ajouté le :{" "}
                                {moment(item.createdAt).format("DD/MMM HH:mm")}
                              </h1>
                            </div>

                            <div className="flex space-x-3">
                              <Button
                                size="xs"
                                variant="light"
                                color={"red"}
                                icon={TrashIcon}
                                onClick={() => {}}
                              >
                                Supprimer
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          </PageLayout>
        </div>

        {modalDeleteVisible && (
          <DeleteConfirm
            loading={loadingDelete}
            onRequestConfirm={() => this.onRequestDelete()}
            onRequestClose={() => {
              this.setState({ modalDeleteVisible: false });
            }}
          />
        )}
      </div>
    );
  }

  onRequestDelete() {
    const { selectedTrailerId } = this.state;
    const { deleteTrailer } = this.props;

    if (deleteTrailer) deleteTrailer(selectedTrailerId);
  }
}

export default PageMovie;
