const offersById = (state) => state.entities.offersById || {};
const plansByOfferId = (state) => state.entities.plansByOfferId || {};

export const getOfferById = (state, itemId) => {
  let collection = offersById(state);
  return collection[itemId] || null;
};

export const getPlansByOfferId = (state, itemId) => {
  let collection = plansByOfferId(state);
  return collection[itemId] || [];
};
