import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Button,
} from "@tremor/react";
import EmptyView from "../../components/empty/large";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import {
  PlusCircleIcon,
  ArrowRightIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";

class PageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_type: "today", // today
      rows_limit: null,
      filter_date: null,
      filter_date2: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getOffers } = this.props;
    if (getOffers) getOffers({});
  }

  render() {
    const { offers = [], loading = true } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={3} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            menuItems={[
              {
                text: "Offres",
                title: "Offres",
                href: "/offers",
                icon: <TicketIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/offers/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Offres & Plans"}>
            <Table className="mt-5">
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Titre</TableHeaderCell>
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>Date création</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Link
                        className="font-semibold hover:text-blue-500"
                        to={`/offers/edit/${item.id}`}
                      >
                        <div className="flex space-x-2 items-center">
                          {item.status === "active" && (
                            <div class="bg-green-500 h-2 w-2 rounded-full" />
                          )}
                          {item.status === "inactive" && (
                            <div class="bg-gray-500 h-2 w-2 rounded-full" />
                          )}
                          <span className="font-semibold">{item.title}</span>
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {item.kind === "PREMIUM" && "Premium"}
                        {item.kind === "FREE" && "Gratuit"}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {moment(item.createdAt).format("DD/MM HH:mm")}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="secondary"
                        icon={ArrowRightIcon}
                        iconPosition="right"
                        size="xs"
                        onClick={() => {
                          this.props.history.push(`/offers/edit/${item.id}`);
                        }}
                      >
                        Modifier
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loading && <ProgressIndeterminate />}
            {Array.from(offers).length === 0 && (
              <div className="my-5">
                <EmptyView
                  title={"Aucune donnée à afficher ici"}
                  description={"Données insuffisantes pour afficher les offres"}
                />
              </div>
            )}
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageList;
