/* eslint-disable no-loop-func */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useCallback, useState } from "react";
import { getSignedUrls, completeMultipartUpload } from "../../api/assetService";
import ProgressCircle from "../progress/circular";
import axios from "axios";
import { slugify, uriFileExtension, uriFileName } from "../../utils/TextUtil";
import { toast } from "react-toastify";
import { prettyBytes } from "../../utils/NumberUtil";
import GoogleChunk from "../../helpers/googleChunk";
import { Divider, Flex, ProgressBar, Text } from "@tremor/react";
import ProgressIndeterminate from "../progress/indeterminate";

const VideoUploader = ({
  handleUri,
  handleUrl,
  handleFile,
  handleFromBlob,
  onProgress,
}) => {
  const [loading, setLoading] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);
  const [assetUrl, setAssetUrl] = useState(null);
  const [assetUri, setAssetUri] = useState(null);
  const [progress, setProgress] = useState(0);

  const onFileChange = (evt) => {
    let files = evt.target.files;
    const [firstFile] = files;

    if (handleFile) handleFile(firstFile);

    setTimeout(() => {
      uploadFile(firstFile);
      let fileInput = document.getElementById("file-upload");
      if (fileInput) fileInput.value = null;
    }, 1000);
  };

  const uploadFile = (file) => {
    let PART_SIZE = 5 * 1024 * 1024; // 7 MB

    setLoading(true);
    setProgressVisible(true);
    const chunk = new GoogleChunk({
      name: "file", // request name
      size: PART_SIZE, // chunk size
      onUploadPart: (blob) => {
        if (handleFromBlob) handleFromBlob(blob);
      },
      onProgress: (progress) => {
        console.log(`${progress}% uploaded...`);
        setProgress(progress);
      },
      onError: (error) => {
        console.log(`error occured : ${error}`);
        setLoading(false);
        setProgressVisible(false);

        toast("Téléchargement échoué", { type: "error" });
      },
      onFinished: (data) => {
        console.log(`finished : ${data}`);

        setLoading(false);
        setProgressVisible(false);
        toast("Téléchargement réussi", { type: "success" });

        const { publicUrl, s3Url } = data;
        setAssetUrl(publicUrl);
        setAssetUri(s3Url);

        if (handleUrl) handleUrl(publicUrl);
        if (handleUri) handleUri(s3Url);
      },
    });
    chunk.setFile(file);
    chunk.commit();
  };

  const uploadFile1 = async (file) => {
    const MINIMAL_SIZE = 500 * 1024 * 1024; // 1 MB

    let PART_SIZE = 15 * 1024 * 1024; // 15 MB
    if (MINIMAL_SIZE * 2 < file.size) {
      PART_SIZE = 20 * 1024 * 1024; // 20 MB
    }
    const noOfParts = Math.ceil(file.size / PART_SIZE);

    console.log("size", prettyBytes(file.size));
    console.log("size", prettyBytes(MINIMAL_SIZE));
    console.log("PART_SIZE", prettyBytes(PART_SIZE));
    console.log("PART_SIZE", noOfParts);

    let _fileName = slugify(file.name);
    let _fileExt = uriFileExtension(file.name);

    setLoading(true);
    const { data } = await getSignedUrls({
      fileName: `${new Date().getTime()}_${_fileName}.${_fileExt}`,
      contentType: file.type,
      fileParts: noOfParts,
    });

    const { urls: presignedUrls, publicUrl, uploadId, uploadKey } = data;
    setAssetUrl(publicUrl);

    const promises = [];
    let fileSizeUploaded = 0;
    let progress = 0;
    let progresses = [];
    // console.log(presignedUrls);

    for (let i = 0; i < noOfParts; i++) {
      const start = i * PART_SIZE;
      const end = (i + 1) * PART_SIZE;
      const blob = i < noOfParts ? file.slice(start, end) : file.slice(start);

      if (handleFromBlob) handleFromBlob(blob);

      try {
        promises.push(
          axios.put(presignedUrls[i], blob).then((res) => {
            fileSizeUploaded += blob.size;
            progresses[i] = (fileSizeUploaded / file.size) * 100;
            console.log({ progress });

            return res;
          })
        );
      } catch (error) {
        console.log(error);
      }
    }

    Promise.all(promises)
      .then((response) => {
        const parts = response.map((part, index) => ({
          ETag: part.headers["etag"],
          PartNumber: index + 1,
        }));

        completeMultipartUpload({ uploadId, uploadKey, parts })
          .then((res) => {
            setLoading(false);
            console.log({ res });

            const response = res.data;
            const { publicUrl, s3Url } = response;

            setAssetUrl(publicUrl);
            setAssetUri(s3Url);

            if (handleUrl) handleUrl(publicUrl);
            if (handleUri) handleUri(s3Url);

            toast("Votre vidéo a été uploadé", {
              type: "success",
              position: "top-center",
            });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);

            toast("Echec de téléchargement", { type: "error" });
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        toast("Echec de téléchargement", { type: "error" });
      });
  };

  return (
    <div>
      <a
        href="javascript:void(0);"
        onClick={() => {
          let fileInput = document.getElementById("file-upload");
          if (fileInput) fileInput.click();
        }}
      >
        <div className="h-[12rem] border-dashed rounded border-2 flex flex-col justify-center items-center space-y-5">
          <h1>Cliquez pour ajouter votre vidéo</h1>
          {loading && <ProgressCircle />}
        </div>
      </a>
      <Divider />

      <Flex className="mt-4">
        <Text className="truncate">{Math.round(progress)}%</Text>
        <Text>Progression du téléchargement</Text>
      </Flex>
      <ProgressBar value={progress} className="mt-2" />

      <input
        className="hidden"
        id={"file-upload"}
        type={"file"}
        accept={"video/mp4,video/x-msvideo"}
        onChange={onFileChange}
      />
    </div>
  );
};

export default VideoUploader;
