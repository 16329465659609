import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  TextInput,
  Select,
  SelectItem,
  Divider,
  Text,
} from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import {
  VideoCameraIcon,
  PlusCircleIcon,
  ListBulletIcon,
  PhotoIcon,
  SignalIcon,
  PencilSquareIcon,
  FolderIcon,
  ArrowRightIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import VideoUploader from "../../components/upload/video";
import { captureVideoFrame, getVideoMetadata } from "../../utils/VideoUtil";
import { getSignedUrl } from "../../api/assetService";
import axios from "axios";
import { toast } from "react-toastify";

class PageTrailerUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trailerType: "trailer",
      trailerTitle: "Bande Annonce",
      contextualSynopsis: "",
      assetUrl: null,
      assetUri: null,
      posterAtworks: [],
      videoDuration: null,
      videoHeight: null,
      videoWidth: null,
      videoType: null,
      blobUrl: null,
    };
    this.video = document.createElement("video");
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onSubmitForm() {
    const {
      contextualSynopsis,
      trailerTitle,
      trailerType,
      posterAtworks,
      assetUri,
      assetUrl,
      videoDuration,
      videoHeight,
    } = this.state;

    if (trailerTitle === "") {
      toast("Veuillez remplir tous les champs", { type: "error" });
      return;
    }

    if (assetUri == null || assetUrl == null) {
      toast("Aucune vidéo n'a été téléchargée", { type: "error" });
      return;
    }

    let bodyParams = {
      title: trailerTitle,
      type: trailerType,
      contextualSynopsis,
      posterAtworks,
      asset: {
        url: assetUri,
        duration: videoDuration,
        resolutionTier: `${videoHeight}p`,
      },
    };

    const { match, addTrailer } = this.props;
    if (addTrailer) addTrailer(match.params.id, bodyParams);
  }

  handleFile = (file) => {
    getVideoMetadata(file)
      .then((data) => {
        console.log("loaded metadata", data);

        this.setState({
          videoHeight: data.videoHeight,
          videoWidth: data.videoWidth,
          videoDuration: data.duration,
          videoType: data.type,
        });
      })
      .catch((err) => {
        toast("Impossible de récupérer les infos de la vidéo", {
          type: "error",
        });
      });
  };

  handleFromBlob = (blob) => {
    if (this.state.posterAtworks.length === 5) return;

    let _context = this;
    let url = (window.URL || window.webkitURL).createObjectURL(blob);

    var onSeeked = function () {
      console.log("onTimeUpdate", video.currentTime);
      _context.takeSnapshot(video);

      window.removeEventListener("seeked", onSeeked);
      (window.URL || window.webkitURL).revokeObjectURL(url);
    };

    var video = document.createElement("video");
    video.src = url;
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      console.log("video.duration: ", video.duration);
      console.log("video.width: ", video.width);
      console.log("video.height: ", video.height);

      video.currentTime = video.duration * 0.35;
    };
    video.addEventListener("seeked", onSeeked);
    video.muted = true;
    video.autoplay = true;
    video.playsInline = true;
  };

  takeSnapshot = async (elementOrId = "video-preview") => {
    try {
      const frame = captureVideoFrame(elementOrId, "jpeg");

      const { data: presignedUrl } = await getSignedUrl({
        fileName: `video-frame-${new Date().getTime()}.${frame.format}`,
        contentType: "image/jpeg",
      });

      const { url, publicUrl } = presignedUrl;
      const { data, headers } = await axios.put(url, frame.blob, {
        headers: { "Content-Type": "image/jpeg" },
      });

      console.log({ presignedUrl, data, headers });

      let posterAtworks = [...this.state.posterAtworks, publicUrl];
      this.setState({ posterAtworks });
    } catch (error) {
      console.log("takeSnapshot [error]", error);
    }
  };

  render() {
    const { assetUri, posterAtworks } = this.state;
    const { match, history, loading } = this.props;
    const titleId = match.params.id;

    const buttonDisabled = assetUri == null || posterAtworks.length === 0;
    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            selectedMenuIndex={2}
            menuItems={[
              {
                text: "Informations",
                title: "Informations",
                href: `/titles/${titleId}`,
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Images",
                title: "Images",
                href: `/titles/${titleId}/atworks`,
                icon: <PhotoIcon className="w-4 h-4" />,
              },
              {
                text: "Bande d'annonces",
                title: "Bande d'annonces",
                href: `/titles/${titleId}/trailers`,
                icon: <VideoCameraIcon className="w-4 h-4" />,
              },
              {
                text: "Episodes",
                title: "Episodes",
                href: `/titles/${titleId}/episodes`,
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
              {
                text: "Médiathèque",
                title: "Médiathèque",
                href: `/titles/${titleId}/medias`,
                icon: <FolderIcon className="w-4 h-4" />,
              },
              {
                text: "Modifier",
                title: "Ajouter",
                href: `/titles/${titleId}/edit`,
                icon: <PencilSquareIcon className="w-4 h-4" />,
              },
              {
                text: "Publication",
                title: "Publication",
                href: `/titles/${titleId}/availability`,
                icon: <SignalIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Nouvelle bande annonce"}>
            <div className="py-10">
              <div className="flex flex-row space-x-5">
                <div className="flex-1 space-y-3">
                  <div>
                    <span className="text-sm text-gray-500">
                      Type de contenu
                    </span>
                    <Select
                      defaultValue="trailer"
                      placeholder="Sélectionner le type"
                      onValueChange={(trailerType) =>
                        this.setState({ trailerType })
                      }
                    >
                      <SelectItem value="trailer">Trailer</SelectItem>
                      <SelectItem value="extra">Bonus</SelectItem>
                    </Select>
                  </div>

                  <div>
                    <span className="text-sm text-gray-500">
                      Titre de la vidéo *
                    </span>
                    <TextInput
                      icon={DocumentIcon}
                      placeholder="Titre"
                      defaultValue="Bande Annonce"
                      onChange={(evt) => {
                        let trailerTitle = evt.target.value;
                        this.setState({ trailerTitle });
                      }}
                    />
                  </div>

                  <div>
                    <span className="text-sm text-gray-500">
                      Description de la vidéo
                    </span>
                    <TextInput
                      icon={DocumentIcon}
                      placeholder="Entrez une brève description (facultatif)"
                      onChange={(evt) => {
                        let contextualSynopsis = evt.target.value;
                        this.setState({ contextualSynopsis });
                      }}
                    />
                  </div>

                  <hr />
                  <Button
                    iconPosition="right"
                    icon={ArrowRightIcon}
                    loading={loading}
                    disabled={buttonDisabled}
                    onClick={() => this.onSubmitForm()}
                  >
                    Enregistrer les informations
                  </Button>
                </div>
                <div className="flex-1 space-y-3">
                  <VideoUploader
                    handleFile={this.handleFile}
                    handleFromBlob={this.handleFromBlob}
                    onProgress={(p) => this.setState({ uploadProgress: p })}
                    handleUri={(assetUri) => this.setState({ assetUri })}
                    handleUrl={(assetUrl) => this.setState({ assetUrl })}
                  />
                  {this.state.uploadProgress && (
                    <div>
                      <Divider />
                      <Text>{this.state.uploadProgress}%</Text>
                    </div>
                  )}
                  <video
                    id={"video-preview"}
                    controls
                    autoPlay={true}
                    style={{ display: "none", marginTop: 10 }}
                  />
                </div>
              </div>
            </div>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageTrailerUpload;
