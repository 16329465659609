import { Link } from "react-router-dom";
import React from "react";
import { classNames } from "../../helpers/classNames";

const DEFAULT_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    className="w-5 h-5"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
    />
  </svg>
);

const DEFAULT_MENU = [
  {
    text: "Menu Item #1",
    title: "Menu Item #1",
    href: "/",
    icon: DEFAULT_ICON,
  },
  {
    text: "Menu Item #2",
    title: "Menu Item #2",
    href: "/",
    icon: DEFAULT_ICON,
  },
  {
    text: "Menu Item #3",
    title: "Menu Item #3",
    href: "/",
    icon: DEFAULT_ICON,
  },
  {
    text: "Menu Item #4",
    title: "Menu Item #4",
    href: "/",
    icon: DEFAULT_ICON,
  },
];

const SideMenu = ({ menuItems = DEFAULT_MENU, selectedMenuIndex = 0 }) => {
  return (
    <div className="flex flex-col h-screen p-3 bg-white shadow w-[20rem]">
      <div className="space-y-3">
        <div className="flex items-center">
          <h2 className="text-xl font-bold">Menu</h2>
        </div>
        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-2">
            {menuItems.map((item, x) => (
              <li className="rounded-sm" key={x}>
                <Link
                  to={item.href}
                  title={item.title}
                  className={classNames(
                    "flex items-center p-2 px-5 space-x-3 rounded-[0.5rem]",
                    selectedMenuIndex === x ? "bg-gray-700" : ""
                  )}
                >
                  <span
                    className={classNames(
                      selectedMenuIndex === x ? "text-white" : "text-gray-500"
                    )}
                  >
                    {item.icon || DEFAULT_ICON}
                  </span>
                  <span
                    className={classNames(
                      selectedMenuIndex === x ? "text-white" : "text-gray-700"
                    )}
                  >
                    {item.text}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
