import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Card, TextInput, Select, SelectItem } from "@tremor/react";
import {
  ClipboardIcon,
  BookmarkIcon,
  PlusCircleIcon,
  TicketIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import SideMenu from "../../components/sidebar/menu";

class PageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerTitle: "",
      offerDescription: "",
      offerStatus: "active",
      offerKind: "PREMIUM",
      lastCreatedId: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { history, lastCreatedId } = this.props;
    if (!lastCreatedId) return;
    if (lastCreatedId !== prevState.lastCreatedId) {
      // history.push("/categories");
    }
  }

  onSubmitForm() {
    const { offerTitle, offerStatus, offerKind } = this.state;
    if (offerTitle === "") {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      title: offerTitle,
      status: offerStatus,
      kind: offerKind,
    };

    const { createOffer } = this.props;
    if (createOffer) createOffer(bodyParams);
  }

  render() {
    const { categories, loading } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            selectedMenuIndex={1}
            menuItems={[
              {
                text: "Offres",
                title: "Offres",
                href: "/offers",
                icon: <TicketIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/offers/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Créer une offre"}>
            <Card>
              <div className="py-10 space-y-3 md:w-[40rem]">
                <TextInput
                  icon={ClipboardIcon}
                  placeholder="Titre de l'offre"
                  onChange={(evt) => {
                    let offerTitle = evt.target.value;
                    this.setState({ offerTitle });
                  }}
                />
                <Select
                  icon={TicketIcon}
                  defaultValue="PREMIUM"
                  placeholder="Type de tarification"
                  onValueChange={(offerKind) => {
                    this.setState({ offerKind });
                  }}
                >
                  <SelectItem value="PREMIUM">Premium</SelectItem>
                  <SelectItem value="FREE">Gratuite</SelectItem>
                </Select>
                <Select
                  icon={BookmarkIcon}
                  defaultValue="active"
                  placeholder="État de la publication"
                  onValueChange={(offerStatus) => {
                    this.setState({ offerStatus });
                  }}
                >
                  <SelectItem value="active">Actif</SelectItem>
                  <SelectItem value="inactive">Désactivé</SelectItem>
                </Select>

                <hr />
                <Button
                  loading={loading}
                  icon={ArrowRightIcon}
                  iconPosition="right"
                  onClick={() => this.onSubmitForm()}
                >
                  Créer & Continuer
                </Button>
              </div>
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageCreate;
