import { connect } from "react-redux";
import { getAtworksById, getTitleById } from "../../selectors/TitleSelectors";
import { addAtwork, getAtworks } from "../../actions/atwork";
import { getTitle } from "../../actions/title";
import component from "../../content/atwork/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  loadingCreate: state.entities.loadingCreate,
  title: getTitleById(state, ownProps.match.params.id),
  atworks: getAtworksById(state, ownProps.match.params.id) || [],
});

const mapDispatchToProps = { addAtwork, getAtworks, getTitle };

export default connect(mapStateToProps, mapDispatchToProps)(component);
