import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabPanels,
  TabPanel,
  TabList,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Badge,
} from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import {
  TrashIcon,
  Squares2X2Icon,
  TvIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import TitleMenu from "../titles/sidemenu";
import DeleteConfirm from "../../components/feedback/deleteModal";
import SeasonCreate from "../season/create";
import SeasonEdit from "../season/edit";

class PageEpisodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      episodeSaisonId: null,
      selectedEpisode: null,
      selectedSeason: null,
      modalCreateVisible: false,
      modalEditVisible: false,
      modalDeleteVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getEpisodes, getSeasons, getTitle, match, title } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getSeasons) getSeasons(match.params.id, {});
    if (getEpisodes) getEpisodes(match.params.id, {});
  }

  render() {
    const {
      modalDeleteVisible,
      modalCreateVisible,
      modalEditVisible,
      selectedSeason,
      episodeSaisonId,
    } = this.state;
    const { episodes, seasons, match, history, loading, title, loadingDelete } =
      this.props;
    const titleId = match.params.id;
    const [firstSeason] = seasons;

    let filterByEpisodeId = episodeSaisonId || firstSeason?.id;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={3} />
          <PageLayout title={"Episodes"}>
            <div className="py-5">
              <TabGroup>
                <TabList className="mt-8">
                  <Tab icon={TvIcon}>Episodes</Tab>
                  <Tab icon={Squares2X2Icon}>Saisons</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <div className="py-5">
                      <div className="md:w-[20%]">
                        <Select
                          icon={Squares2X2Icon}
                          placeholder="Sélectionner la saison"
                          value={filterByEpisodeId}
                          onValueChange={(episodeSaisonId) =>
                            this.setState({ episodeSaisonId })
                          }
                        >
                          {seasons.map((item) => (
                            <SelectItem value={item.id}>{item.name}</SelectItem>
                          ))}
                        </Select>
                      </div>

                      <Table className="mt-5">
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>Illustration</TableHeaderCell>
                            <TableHeaderCell>Titre</TableHeaderCell>
                            <TableHeaderCell>Création</TableHeaderCell>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {episodes
                            .filter(
                              (item) => item.seasonId === filterByEpisodeId
                            )
                            .map((item, x) => (
                              <TableRow key={item.id}>
                                <TableCell>
                                  <div className="h-[5rem] w-[7rem]">
                                    <img
                                      alt={item.title}
                                      src={item.posterAtworks[0]}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                </TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>
                                  {moment(item.createdAt).format(
                                    "DD/MMM HH:mm"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {item.isPublished ? (
                                    <Badge color="green">Publié</Badge>
                                  ) : (
                                    <Badge color="gray">Non publié</Badge>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <div className="space-x-3">
                                    <Button
                                      variant="secondary"
                                      icon={PencilIcon}
                                      iconPosition="right"
                                      size="xs"
                                      onClick={() => {
                                        history.push(
                                          `/titles/${titleId}/episodes/${item.id}`
                                        );
                                      }}
                                    >
                                      Modifier
                                    </Button>
                                    <Button
                                      size="xs"
                                      color="red"
                                      variant="secondary"
                                      icon={TrashIcon}
                                      iconPosition="right"
                                      disabled={true}
                                      onClick={() => {}}
                                    >
                                      Retirer
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>

                      <hr className="my-5" />
                      <Button
                        size="xs"
                        loading={loading}
                        variant="secondary"
                        icon={PlusCircleIcon}
                        iconPosition="right"
                        onClick={() => {
                          history.push(`/titles/${titleId}/episodes/new`);
                        }}
                      >
                        Ajouter une épisode
                      </Button>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Table className="mt-5">
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Numéro</TableHeaderCell>
                          <TableHeaderCell>Titre</TableHeaderCell>
                          <TableHeaderCell>Création</TableHeaderCell>
                          <TableHeaderCell>Status</TableHeaderCell>
                          <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {seasons.map((item, x) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.shortName}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                              {moment(item.createdAt).format("DD/MMM HH:mm")}
                            </TableCell>
                            <TableCell>
                              {item.isPublished ? (
                                <Badge color="green">Publié</Badge>
                              ) : (
                                <Badge color="gray">Non publié</Badge>
                              )}
                            </TableCell>
                            <TableCell>
                              <div className="space-x-3">
                                <Button
                                  variant="secondary"
                                  icon={PencilIcon}
                                  iconPosition="right"
                                  size="xs"
                                  onClick={() => {
                                    this.setState({
                                      selectedSeason: item,
                                      modalEditVisible: true,
                                    });
                                  }}
                                >
                                  Modifier
                                </Button>
                                <Button
                                  size="xs"
                                  color="red"
                                  variant="secondary"
                                  icon={TrashIcon}
                                  iconPosition="right"
                                  disabled={true}
                                  onClick={() => {}}
                                >
                                  Retirer
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <hr className="my-5" />
                    <Button
                      size="xs"
                      loading={loading}
                      variant="secondary"
                      icon={PlusCircleIcon}
                      iconPosition="right"
                      onClick={() => {
                        this.setState({ modalCreateVisible: true });
                      }}
                    >
                      Ajouter une saison
                    </Button>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
          </PageLayout>
        </div>

        {modalDeleteVisible && (
          <DeleteConfirm
            loading={loadingDelete}
            onRequestConfirm={() => this.onRequestDelete()}
            onRequestClose={() => {
              this.setState({ modalDeleteVisible: false });
            }}
          />
        )}

        {modalCreateVisible && (
          <SeasonCreate
            titleId={titleId}
            seasonsCount={seasons.length}
            onRequestClose={() => {
              this.setState({ modalCreateVisible: false });
            }}
          />
        )}

        {modalEditVisible && (
          <SeasonEdit
            season={selectedSeason}
            onRequestClose={() => {
              this.setState({ modalEditVisible: false });
            }}
          />
        )}
      </div>
    );
  }

  onRequestDelete() {
    const { selectedSeason } = this.state;
    const { deleteTrailer } = this.props;

    if (deleteTrailer) deleteTrailer(selectedSeason);
  }
}

export default PageEpisodes;
