import axios from "axios";
import { API_BASE_HOST } from "../constants/api";

const ApiContext = axios.create({
  baseURL: API_BASE_HOST,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "multipart/form-data" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/?logout=auto");
    }
    return Promise.reject(error.response);
  }
);

export function uploadImage(bodyParams, params = {}) {
  return ApiContext.post(`assets/upload`, bodyParams, { params });
}

export function getSignedUrl(params = {}) {
  return ApiContext.get(`uploads/signed_url`, { params });
}

export function getSignedUrls(params = {}) {
  return ApiContext.get(`uploads/signed_urls`, { params });
}

export function presignedPost(params = {}) {
  return ApiContext.get(`uploads/presigned_post`, { params });
}

export function completeMultipartUpload(params = {}) {
  return ApiContext.post(`uploads/multipart_complete`, params, {
    headers: { "Content-Type": "application/json" },
  });
}

export function youtubeDetails(params = {}) {
  return ApiContext.get(`external-services/youtube`, { params });
}
