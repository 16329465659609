export function youtubeId(url) {
  var youtubeId = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    youtubeId = url[2].split(/[^0-9a-z_\-]/i);
    youtubeId = youtubeId[0];
  } else {
    youtubeId = url;
  }

  return youtubeId;
}

export const vimeoId = (url) => {
  var videoId = "";
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    videoId = match[1];
  }

  return videoId;
};

export function youtubeEmbedUrl(url) {
  var videoId = youtubeId(url);

  return `https://www.youtube.com/embed/${videoId}`;
}

export const vimeoEmbedUrl = (url) => {
  var videoId = vimeoId(url);

  return `https://player.vimeo.com/video/${videoId}`;
};

export function durationToSeconds(duration) {
  var total = 0;
  var hours = duration.match(/(\d+)H/);
  var minutes = duration.match(/(\d+)M/);
  var seconds = duration.match(/(\d+)S/);
  if (hours) total += parseInt(hours[1]) * 3600;
  if (minutes) total += parseInt(minutes[1]) * 60;
  if (seconds) total += parseInt(seconds[1]);
  return total;
}
