import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Badge,
  Button,
} from "@tremor/react";
import {
  PlusCircleIcon,
  ListBulletIcon,
  ArrowRightIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import EmptyView from "../../components/empty/large";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";

class PageList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getTitles } = this.props;
    if (getTitles) getTitles({});
  }

  render() {
    const { titles, loading } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            menuItems={[
              {
                text: "Titres",
                title: "Titres",
                href: "/titles",
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/titles/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Tous les titres"}>
            <Table className="mt-5">
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Titre</TableHeaderCell>
                  <TableHeaderCell>Type</TableHeaderCell>
                  <TableHeaderCell>Année</TableHeaderCell>
                  <TableHeaderCell>Création</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {titles.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.title}</TableCell>
                    <TableCell>
                      <Text>{item.releaseYear}</Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {item.contentType === "episodic" && "Série"}
                        {item.contentType === "movie" && "Court-Métrage"}
                        {item.contentType === "movie_long" && "Long-Metrage"}
                        {item.contentType === "show" && "Court-Metrage"}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {moment(item.createdAt).format("DD/MM HH:mm")}
                      </Text>
                    </TableCell>
                    <TableCell>
                      {item.isPublished && (
                        <Badge color="emerald" icon={BookmarkIcon}>
                          Publié
                        </Badge>
                      )}
                      {!item.isPublished && (
                        <Badge color="gray" icon={BookmarkIcon}>
                          Non publié
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="secondary"
                        icon={ArrowRightIcon}
                        iconPosition="right"
                        size="xs"
                        onClick={() => {
                          let detailUrl = `/titles/${item.id}`;
                          this.props.history.push(detailUrl);
                        }}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {loading && <ProgressIndeterminate />}
            {Array.from(titles).length === 0 && (
              <div className="my-5">
                <EmptyView
                  title={"Aucune donnée à afficher ici"}
                  description={"Données insuffisantes pour afficher les titles"}
                />
              </div>
            )}
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageList;
