import React, { Component, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
  TagIcon,
  GlobeAltIcon,
  QueueListIcon,
  ChartBarIcon,
  TicketIcon,
  CogIcon,
} from "@heroicons/react/24/outline";
import { getCookie } from "../../helpers/cookies";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { accountId, selectedIndex = 0 } = this.props;
    if (!accountId) accountId = localStorage.getItem("accounts__current_id");
    let accountUsername = getCookie("account_username");

    const navItems = [
      {
        name: "Dashboard",
        description: "Measure actions your users take",
        href: `/dashboard`,
        icon: ChartBarIcon,
      },
      {
        name: "Titres",
        description: "Create your own targeted content",
        href: `/titles`,
        icon: QueueListIcon,
      },
      {
        name: "Catégories",
        description: "Keep track of your growth",
        href: `/categories`,
        icon: TagIcon,
      },
      {
        name: "Offres",
        description: "Keep track of your growth",
        href: `/offers`,
        icon: TicketIcon,
      },
    ];

    const navItems1 = [
      {
        name: "Mon compte",
        description: "Measure actions your users take",
        href: `#`,
        icon: TagIcon,
      },
      {
        name: "Mes appareils",
        description: "Measure actions your users take",
        href: `#`,
        icon: TagIcon,
      },
      {
        name: "Modèles de formulaire",
        description: "Measure actions your users take",
        href: `#`,
        icon: TagIcon,
      },
      {
        name: "Visitez notre centre d'aide",
        description: "Keep track of your growth",
        href: `#`,
        icon: TagIcon,
      },
      {
        name: "Deconnexion",
        description: "Keep track of your growth",
        href: `#`,
        icon: TagIcon,
      },
    ];

    return (
      <Popover className="relative">
        <header className="sticky top-0 z-50 flex flex-wrap items-center justify-between bg-primary-500 px-4 shadow-md shadow-slate-900/5 transition duration-500 dark:shadow-none sm:px-6 lg:px-8">
          <div className="mr-6"></div>
          <div className="flex items-center space-x-5">
            {navItems.map((item, index) => (
              <Link
                to={item.href}
                className={classNames(
                  "py-3 border-b-2",
                  selectedIndex === index
                    ? "border-white"
                    : "border-transparent"
                )}
              >
                <div className={"px-3 flex space-x-3 items-center"}>
                  <item.icon
                    aria-hidden="true"
                    className="h-4 w-4 stroke-white text-white"
                  />
                  <span className="text-sm font-medium text-white uppercase">
                    {item.name}
                  </span>
                </div>
              </Link>
            ))}
          </div>
          <div className="">
            <Popover className="relative">
              <Popover.Button
                type="button"
                className="relative p-1 flex items-center justify-center rounded-lg dark:bg-slate-700 dark:ring-inset dark:ring-white/5"
                aria-label="Open navigation"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 text-white stroke-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 py-3">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-[20rem]">
                    <div className="grid grid-cols-1 bg-white p-7 gap-8">
                      <h1 className="font-semibold text-sm">
                        {accountId} - {accountUsername}
                      </h1>
                      <hr />
                      {navItems1
                        .filter((_, index) => index < 4)
                        .map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex h-7 w-7 sm:h-8 sm:w-8 shrink-0 items-center justify-center text-gray-500 bg-yellow-200">
                              <item.icon
                                aria-hidden="true"
                                className="h-4 w-4"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                            </div>
                          </a>
                        ))}
                      <hr />
                      {navItems1
                        .filter((_, index) => index > 3)
                        .map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex h-7 w-7 sm:h-8 sm:w-8 shrink-0 items-center justify-center text-gray-500 bg-yellow-200">
                              <item.icon
                                aria-hidden="true"
                                className="h-4 w-4"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-gray-900">
                                {item.name}
                              </p>
                            </div>
                          </a>
                        ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          </div>
        </header>
      </Popover>
    );
  }

  renderMenuButton() {
    return null;
  }
}

export default HeaderAdmin;
