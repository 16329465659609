import React, { Component } from "react";
import {
  Button,
  Card,
  TextInput,
  Select,
  SelectItem,
  MultiSelect,
  MultiSelectItem,
} from "@tremor/react";
import {
  VideoCameraIcon,
  PlusCircleIcon,
  ListBulletIcon,
  PhotoIcon,
  SignalIcon,
  PencilSquareIcon,
  FolderIcon,
  ClipboardIcon,
  BookmarkIcon,
  CalendarDaysIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import { toast } from "react-toastify";
import TitleMenu from "./sidemenu";
import TextFields from "../../components/form/textfields";

class PageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      shortDescription: "",
      longDescription: "",
      releaseYear: "",
      primaryCategoryId: "",
      categoryIds: [],
      languagesSubtitles: ["fr"],
      languagesAudios: ["fr"],
      contentType: "movie",
      isOriginal: "notOriginal",
      titleId: null,
      lastEditedId: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchData(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { title } = this.props;

    if (title) {
      if (title.id !== prevState.titleId) {
        this.setState({
          title: title.title || "",
          shortDescription: title.shortDescription || "",
          longDescription: title.longDescription || "",
          releaseYear: title.releaseYear || "",
          primaryCategoryId: title.primaryCategoryId || "",
          categoryIds: title.categoryIds || [],
          languagesSubtitles: title.languagesSubtitles || ["fr"],
          languagesAudios: title.languagesAudios || ["fr"],
          contentType: title.contentType || "movie",
          isOriginal: title.isOriginal ? "isOriginal" : "notOriginal",
          titleId: title.id,
        });
      }
    }
  }

  componentWillUnmount() {}

  fetchData() {
    const { match, getTitle, getCategories } = this.props;
    if (getCategories) getCategories({});
    if (getTitle) getTitle(match.params.id);
  }

  onSubmitForm() {
    const {
      title,
      shortDescription,
      longDescription,
      contentType,
      isOriginal,
      releaseYear,
      languagesAudios,
      languagesSubtitles,
      categoryIds,
      primaryCategoryId,
    } = this.state;
    if (
      title === "" ||
      contentType === "" ||
      shortDescription === "" ||
      primaryCategoryId === "" ||
      releaseYear === ""
    ) {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      title,
      contentType,
      primaryCategoryId,
      categoryIds,
      shortDescription,
      longDescription,
      languagesAudios,
      languagesSubtitles,
      isOriginal: isOriginal === "isOriginal",
    };

    const { editTitle, match } = this.props;
    if (editTitle) editTitle(match.params.id, bodyParams);
  }

  render() {
    const {
      categoryIds,
      primaryCategoryId,
      title,
      shortDescription,
      longDescription,
      releaseYear,
      contentType,
      isOriginal,
    } = this.state;
    const { title: currentTitle, match, categories, loading } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={4} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={currentTitle} selectedMenuIndex={5} />
          <PageLayout title={"Modifier votre titre"}>
            <Card>
              {currentTitle && (
                <div className="py-10 space-y-3 md:w-[40rem]">
                  <Select
                    icon={BookmarkIcon}
                    value={contentType}
                    placeholder="Format"
                    onValueChange={(contentType) => {
                      this.setState({ contentType });
                    }}
                  >
                    <SelectItem value="movie">Court-Metrage</SelectItem>
                    <SelectItem value="movie_long">Long-Metrage</SelectItem>
                    <SelectItem value="episodic">Série</SelectItem>
                  </Select>

                  <Select
                    icon={BookmarkIcon}
                    value={primaryCategoryId}
                    placeholder="Catégorie principale"
                    onValueChange={(primaryCategoryId) => {
                      this.setState({
                        primaryCategoryId,
                        categoryIds: [primaryCategoryId],
                      });
                    }}
                  >
                    {categories.map((item, x) => (
                      <SelectItem key={x} value={item.id}>
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>

                  <MultiSelect
                    icon={BookmarkIcon}
                    value={categoryIds}
                    placeholder="Catégories sécondaires"
                    onValueChange={(categoryIds) => {
                      console.log(categoryIds);
                      this.setState({ categoryIds });
                    }}
                  >
                    {categories.map((item, x) => (
                      <MultiSelectItem key={x} value={item.id}>
                        {item.name}
                      </MultiSelectItem>
                    ))}
                  </MultiSelect>

                  <TextInput
                    icon={ClipboardIcon}
                    value={title}
                    placeholder="Titre"
                    onChange={(evt) => {
                      let title = evt.target.value;
                      this.setState({ title });
                    }}
                  />

                  <Select
                    icon={BookmarkIcon}
                    value={isOriginal}
                    placeholder="Contenu original ?"
                    onValueChange={(isOriginal) => {
                      this.setState({ isOriginal });
                    }}
                  >
                    <SelectItem value="isOriginal">Titre Original</SelectItem>
                    <SelectItem value="notOriginal">
                      Titre Non Original
                    </SelectItem>
                  </Select>

                  <TextInput
                    icon={CalendarDaysIcon}
                    value={releaseYear}
                    placeholder="Année de sortie"
                    onChange={(evt) => {
                      let releaseYear = evt.target.value;
                      this.setState({ releaseYear });
                    }}
                  />

                  <TextFields
                    label="Accroche courte"
                    nameOrId={"shortDescription"}
                    placeholder="Entrez votre accroche ici..."
                    value={shortDescription}
                    onChange={(shortDescription) => {
                      this.setState({ shortDescription });
                    }}
                  />

                  <TextFields
                    label="Synospis du titre"
                    nameOrId={"longDescription"}
                    placeholder="Entrez votre synospis ici..."
                    value={longDescription}
                    onChange={(longDescription) => {
                      this.setState({ longDescription });
                    }}
                  />

                  <hr />
                  <Button
                    loading={loading}
                    icon={ArrowRightIcon}
                    iconPosition="right"
                    onClick={() => this.onSubmitForm()}
                  >
                    Enregistrer les informations
                  </Button>
                </div>
              )}
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageEdit;
