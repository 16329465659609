import { toast } from "react-toastify";
import {
  offerCreate,
  offerEdit,
  offerSingle,
  offerTitles,
  offersList,
  planCreate,
} from "../api/offerService";
import { getCookie } from "../helpers/cookies";
import { getPlans } from "./plan";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

/**
 * Get offers inside entities
 * @param {*} offerId
 * @param {*} params
 * @returns
 */
export const getOffers =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    offersList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { offers } = response;

        if (offers) {
          dispatch(setKeyValue("offers", offers));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };

/**
 * Get offers inside entities
 * @param {*} offerId
 * @param {*} params
 * @returns
 */
export const getOffer =
  (offerId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    offerSingle(offerId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { offer } = response;

        if (offer) {
          let offersById = { ...entities.offersById };
          offersById[offerId] = offer;
          dispatch(setKeyValue("offersById", offersById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
      });
  };

/**
 * Create a member offer inside entities
 *
 * @param {*} offerId
 * @param {*} body
 * @param {*} params
 * @returns
 */

export const createOffer =
  (body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    offerCreate(body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { offer } = response;

        if (offer) {
          window.location.replace(`/offers/edit/${offer.id}`);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible de terminer cette opération", { type: "error" });
      });
  };

/**
 * Create a member offer inside entities
 *
 * @param {*} offerId
 * @param {*} body
 * @param {*} params
 * @returns
 */

export const editOffer =
  (offerId, body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingEdit", true));
    offerEdit(offerId, body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingEdit", false));

        let response = result.data;
        const { offer } = response;

        if (offer) {
          toast("Modifications enregistrées", { type: "success" });
          dispatch(getOffer(offerId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingEdit", false));
        toast("Echec lors de l'enregistrement", { type: "error" });
      });
  };

/**
 * Create a member offer inside entities
 *
 * @param {*} offerId
 * @param {*} body
 * @param {*} params
 * @returns
 */

export const getTitles =
  (offerId, body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingItems", true));
    offerTitles(offerId, body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingItems", false));

        let response = result.data;
        const { titles } = response;

        if (titles) {
          let titlesByOffer = { ...entities.titlesByOffer };
          titlesByOffer[offerId] = titles;

          dispatch(setKeyValue("titlesByOffer", titlesByOffer));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingItems", false));
        toast("Impossible de charger les éléments", { type: "warning" });
      });
  };

/**
 * Create a member offer inside entities
 *
 * @param {*} offerId
 * @param {*} body
 * @param {*} params
 * @returns
 */

export const addTitles =
  (offerId, body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingEdit", true));
    offerTitles(offerId, body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingEdit", false));

        let response = result.data;
        const { titles } = response;

        if (titles) {
          dispatch(getOffer(offerId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingEdit", false));
      });
  };

/**
 * Create a member offer inside entities
 *
 * @param {*} offerId
 * @param {*} body
 * @param {*} params
 * @returns
 */

export const createPlan =
  (offerId, body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    planCreate(offerId, body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { plan } = response;

        if (plan) {
          toast("Votre prix a été enregistré !", { type: "success" });

          dispatch(getOffer(offerId));
          dispatch(getPlans(offerId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        toast("Echec d'enregistrement de votre prix !");
        dispatch(setKeyValue("loadingCreate", false));
      });
  };
