import { connect } from "react-redux";
import {
  getTitleById,
  getMovieById,
  getMoviesById,
} from "../../selectors/TitleSelectors";
import { getTitle } from "../../actions/title";
import { getMovies } from "../../actions/movie";
import component from "../../content/movie/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  loadingDelete: state.entities.loadingDelete,
  title: getTitleById(state, ownProps.match.params.id),
  movie: getMovieById(state, ownProps.match.params.id),
  movies: getMoviesById(state, ownProps.match.params.id) || [],
});

const mapDispatchToProps = { getTitle, getMovies };

export default connect(mapStateToProps, mapDispatchToProps)(component);
