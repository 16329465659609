import moment from "moment";
import React, { Component } from "react";
import {
  Text,
  Badge,
  Button,
  Card,
  TextInput,
  Select,
  SelectItem,
} from "@tremor/react";
import {
  ClipboardIcon,
  BookmarkIcon,
  PlusCircleIcon,
  ListBulletIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import EmptyView from "../../components/empty/large";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import { toast } from "react-toastify";

class PageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: "",
      categoryDescription: "",
      categoryPined: "notPined",
      lastCreatedId: null,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { history, lastCreatedId } = this.props;
    if (!lastCreatedId) return;
    if (lastCreatedId !== prevState.lastCreatedId) {
      // history.push("/categories");
    }
  }

  onSubmitForm() {
    const { categoryName, categoryDescription, categoryPined } = this.state;
    if (categoryName === "" || categoryDescription === "") {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      name: categoryName,
      description: categoryDescription,
      isPined: categoryPined === "isPined",
      isPublished :true,
    };

    const { createCategory } = this.props;
    if (createCategory) createCategory(bodyParams);
  }

  render() {
    const { categories, loading } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            selectedMenuIndex={1}
            menuItems={[
              {
                text: "Catégories",
                title: "Categories",
                href: "/categories",
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/categories/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Créer une categorie"}>
            <Card>
              <div className="py-10 space-y-3 md:w-[40rem]">
                <TextInput
                  icon={ClipboardIcon}
                  placeholder="Nom de la catégorie"
                  onChange={(evt) => {
                    let categoryName = evt.target.value;
                    this.setState({ categoryName });
                  }}
                />
                <TextInput
                  icon={ClipboardIcon}
                  placeholder="Description de la catégorie"
                  onChange={(evt) => {
                    let categoryDescription = evt.target.value;
                    this.setState({ categoryDescription });
                  }}
                />
                <Select
                  icon={BookmarkIcon}
                  placeholder="Epingler à la page d'accueil ?"
                  onValueChange={(categoryPined) => {
                    this.setState({ categoryPined });
                  }}
                >
                  <SelectItem value="isPined">OUI</SelectItem>
                  <SelectItem value="notPined">NON</SelectItem>
                </Select>

                <hr />
                <Button
                  icon={ArrowRightIcon}
                  iconPosition="right"
                  onClick={() => this.onSubmitForm()}
                >
                  Créer la catégorie
                </Button>
              </div>
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageCreate;
