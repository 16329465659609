import moment from "moment";
import React, { Component } from "react";
import EmptyView from "../../components/empty/large";
import Button from "../../components/form/button";
import DatePicker from "../../components/form/datepicker";
import Select from "../../components/form/select";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SidebarBase from "../../components/sidebar/base";
import StatisticCard from "../../components/statistics/base";
import Table from "../../components/table/table";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_type: "today", // today
      rows_limit: null,
      filter_date: null,
      filter_date2: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
    this.fetchInterval = setInterval(() => {
      this.fetchDataIfNeeded();
    }, 8500);
  }

  componentWillUnmount() {
    if (this.fetchInterval) clearInterval(this.fetchInterval);
  }

  fetchDataIfNeeded() {
    const { filter_type, filter_date, filter_date2 } = this.state;
    const { getStatistics, match } = this.props;
    const { params } = match;
    const { aid: accountId } = params;

    let qParams = {
      date1: filter_date || null,
      date2: filter_date2 || null,
      per_page: 20,
      filter_type,
    };
    if (getStatistics) getStatistics(accountId, qParams);
  }

  render() {
    const { match, statistics, transactions, reportButtons, loading } =
      this.props;
    const { params } = match;
    const { aid: accountId } = params;

    return (
      <div>
        <PageHeader />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SidebarBase accountId={accountId} />
          <PageLayout title={"Tableau de bord"}>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
              {statistics.map((item, index) => (
                <StatisticCard
                  headerText={item.title}
                  bigText={item.value}
                  subText={item.description}
                  icon={item.icon}
                  key={index}
                />
              ))}
            </div>

            {Array.from(statistics).length == 0 && (
              <div className="my-5">
                <EmptyView
                  title={"Aucune donnée à afficher ici"}
                  description={"Données insuffisantes pour afficher le rapport"}
                />
              </div>
            )}
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default Dashboard;
