/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  TextInput,
  Select,
  SelectItem,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from "@tremor/react";
import {
  ClipboardIcon,
  BookmarkIcon,
  PlusCircleIcon,
  TicketIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import SideMenu from "../../components/sidebar/menu";
import RichText from "../../components/form/richtext";
import Atwork from "../../components/upload/atwork";
import DataList from "../../components/list";
import PlanEdit from "../plan/edit";
import { classNames } from "../../helpers/classNames";

class PageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offerTitle: "",
      offerDescription: "",
      offerKind: "PREMIUM",
      offerStatus: "active",
      atworkUrl: null,
      offerId: null,
      selectedRowsKeys: [],
      selectedActiveRowsKeys: [],
      activeTitleIds: [],
      selectedPlanId: null,
      selectedPlan: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchData(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, offer } = this.props;
    if (offer) {
      if (offer.id !== prevState.offerId) {
        this.setState({
          offerTitle: offer.title,
          atworkUrl: offer.atworkUrl,
          offerDescription: offer.description,
          offerType: offer.type || "svod",
          offerKind: offer.kind || "PREMIUM",
          offerStatus: offer.status || "active",
          activeTitleIds: offer.titlesIds || [],
          offerId: offer.id,
        });
      }
    }
  }

  fetchData() {
    const { getOffer, getTitles, getPlans, match } = this.props;
    if (getTitles) getTitles({ includeAtworks: 1 });
    if (getOffer) getOffer(match.params.id);
    if (getPlans) getPlans(match.params.id);
  }

  switchTitles(direction = "right") {
    let { activeTitleIds, selectedRowsKeys, selectedActiveRowsKeys } =
      this.state;

    console.log("activeTitleIds", activeTitleIds);
    console.log("selectedRowsKeys", selectedRowsKeys);
    console.log("selectedActiveRowsKeys", selectedActiveRowsKeys);

    if (direction === "right") {
      activeTitleIds = [...activeTitleIds, ...selectedRowsKeys];
    } else {
      activeTitleIds = activeTitleIds.filter(
        (t) => !selectedActiveRowsKeys.includes(t)
      );
    }

    this.setState({
      activeTitleIds,
      selectedRowsKeys: [],
      selectedActiveRowsKeys: [],
    });
  }

  onImageUploaded = (location, atworkUrl) => this.setState({ atworkUrl });

  onSubmitForm() {
    const {
      offerTitle,
      offerDescription,
      offerStatus,
      atworkUrl,
      activeTitleIds,
    } = this.state;
    if (offerTitle === "") {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      title: offerTitle,
      status: offerStatus,
      description: offerDescription,
      titleIds: activeTitleIds,
      atworkUrl,
    };

    const { editOffer, match } = this.props;
    if (editOffer) editOffer(match.params.id, bodyParams);
  }

  onCreateNewPlan() {
    const { offerKind } = this.state;
    let bodyParams = { platform: "maxicash", price: 0.5, currency: "USD" };
    if (offerKind === "FREE") {
      bodyParams = {
        price: 0,
        currency: "USD",
        type: "free_tier",
        platform: "congarts_tv",
        frequencyDuration: -1,
        frequencyUnit: "unlimited",
      };
    }

    const { createPlan, match } = this.props;
    if (createPlan) createPlan(match.params.id, bodyParams);
  }

  onConfirmPlanDelete(planId) {
    const { deletePlan, match } = this.props;
    let params = { offerId: match.params.id };
    if (deletePlan) deletePlan(planId, params);
  }

  render() {
    const {
      titles = [],
      plans = [],
      loading,
      loadingPlan,
      loadingCreate,
      editPlan,
    } = this.props;
    const {
      offerDescription,
      offerStatus,
      offerTitle,
      atworkUrl,
      selectedRowsKeys,
      selectedActiveRowsKeys,
      activeTitleIds,
      selectedPlanId,
      selectedPlan,
    } = this.state;

    const leftItems = titles.filter((e) => !activeTitleIds.includes(e.id));
    const rightItems = titles.filter((e) => activeTitleIds.includes(e.id));

    return (
      <div>
        <PageHeader selectedIndex={3} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            menuItems={[
              {
                text: "Offres",
                title: "Offres",
                href: "/offers",
                icon: <TicketIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/offers/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={`Modifier une offre`}>
            <Card>
              <div className="py-10 space-y-5">
                <div className="md:w-[65%] space-y-3">
                  <TextInput
                    icon={ClipboardIcon}
                    placeholder="Titre de l'offre"
                    value={offerTitle}
                    onChange={(evt) => {
                      let offerTitle = evt.target.value;
                      this.setState({ offerTitle });
                    }}
                  />

                  <RichText
                    icon={ClipboardIcon}
                    nameOrId={"offer-description"}
                    placeholder="Entrez une description"
                    label="Description"
                    value={offerDescription}
                    onChange={(offerDescription) => {
                      this.setState({ offerDescription });
                    }}
                  />
                  <Select
                    icon={BookmarkIcon}
                    defaultValue="active"
                    placeholder="État de la publication"
                    value={offerStatus}
                    onValueChange={(offerStatus) => {
                      this.setState({ offerStatus });
                    }}
                  >
                    <SelectItem value="active">Actif</SelectItem>
                    <SelectItem value="inactive">Désactivé</SelectItem>
                  </Select>
                </div>

                <hr />
                <div className="py-2">
                  <h1 className="font-semibold text-md">
                    Image d'illustration
                  </h1>

                  <div className="w-[24rem] h-[15rem] my-10">
                    <Atwork
                      textAlt="Illustration au format 16/9"
                      location={"TITLE_ALPHA"}
                      onImageUploaded={this.onImageUploaded}
                      photo={atworkUrl && { url: atworkUrl }}
                    />
                  </div>
                </div>

                <hr />
                <div className="py-2">
                  <h1 className="font-semibold text-md">Éléments</h1>

                  <div className="flex space-x-2 py-10">
                    <div className="flex-1">
                      <DataList
                        title={"Bibliothèque"}
                        items={leftItems}
                        selectedRowKeys={selectedRowsKeys}
                        onSearchText={(text) => {
                          console.log("text:", text);
                        }}
                        onItemChecked={(checked, rowKey) => {
                          console.log("onItemChecked [checked]:", checked);
                          console.log("onItemChecked [rowKey]:", rowKey);

                          if (checked) {
                            this.setState({
                              selectedRowsKeys: [
                                ...this.state.selectedRowsKeys,
                                rowKey,
                              ],
                            });
                          } else {
                            this.setState({
                              selectedRowsKeys:
                                this.state.selectedRowsKeys.filter(
                                  (t) => t.id !== rowKey
                                ),
                            });
                          }
                        }}
                        onItemsChecked={(checked) => {
                          if (checked) {
                            this.setState({
                              selectedRowsKeys: leftItems.map((e) => e.id),
                            });
                          } else {
                            this.setState({ selectedRowsKeys: [] });
                          }
                        }}
                      />
                    </div>
                    <div className="w-[10%] flex flex-col items-center justify-center space-y-5">
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.switchTitles("right")}
                      >
                        <div className="border rounded-sm p-4">
                          <ArrowRightIcon className="w-5 h-5" />
                        </div>
                      </a>
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.switchTitles("left")}
                      >
                        <div className="border rounded-sm p-4">
                          <ArrowLeftIcon className="w-5 h-5" />
                        </div>
                      </a>
                    </div>
                    <div className="flex-1">
                      <DataList
                        title={"Bibliothèque"}
                        items={rightItems}
                        selectedRowKeys={selectedActiveRowsKeys}
                        onSearchText={(text) => {
                          console.log("text:", text);
                        }}
                        onItemChecked={(checked, rowKey) => {
                          console.log("onItemChecked [checked]:", checked);
                          console.log("onItemChecked [rowKey]:", rowKey);

                          if (checked) {
                            this.setState({
                              selectedActiveRowsKeys: [
                                ...this.state.selectedActiveRowsKeys,
                                rowKey,
                              ],
                            });
                          } else {
                            this.setState({
                              selectedActiveRowsKeys:
                                this.state.selectedActiveRowsKeys.filter(
                                  (t) => t.id !== rowKey
                                ),
                            });
                          }
                        }}
                        onItemsChecked={(checked) => {
                          if (checked) {
                            this.setState({
                              selectedActiveRowsKeys: rightItems.map(
                                (e) => e.id
                              ),
                            });
                          } else {
                            this.setState({ selectedActiveRowsKeys: [] });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <hr />
                <div className="py-2">
                  <h1 className="font-semibold text-md">
                    Configuration de l’offre
                  </h1>

                  <div className="shadow-lg border py-10 my-10">
                    <div className="flex justify-between items-center px-10">
                      <h1 className="font-semibold text-md">Abonnement</h1>
                      <Button
                        loading={loadingPlan || loadingCreate}
                        icon={PlusCircleIcon}
                        iconPosition="right"
                        onClick={() => this.onCreateNewPlan()}
                      >
                        Ajouter un prix
                      </Button>
                    </div>
                    <Table className="mt-5">
                      <TableHead>
                        <TableRow className="border-b">
                          <TableHeaderCell className="w-[7%]">
                            Status
                          </TableHeaderCell>
                          <TableHeaderCell>Platforme</TableHeaderCell>
                          <TableHeaderCell>Période</TableHeaderCell>
                          <TableHeaderCell>Prix</TableHeaderCell>
                          <TableHeaderCell>Jour(s) d'essai</TableHeaderCell>
                          <TableHeaderCell>Résolution</TableHeaderCell>
                          <TableHeaderCell>Restrictions</TableHeaderCell>
                          <TableHeaderCell>Abonnés</TableHeaderCell>
                          <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {plans.map((item, x) => (
                          <TableRow
                            key={x}
                            className={classNames(
                              "border-l-8",
                              item.id === selectedPlanId
                                ? "border-l-blue-500"
                                : "border-transparent"
                            )}
                          >
                            <TableCell className="flex space-x-2 items-center">
                              {item.status === "active" && (
                                <div class="bg-green-500 h-2 w-2 rounded-full" />
                              )}
                              {item.status === "inactive" && (
                                <div class="bg-gray-500 h-2 w-2 rounded-full" />
                              )}
                              <span>{item.status}</span>
                            </TableCell>
                            <TableCell>{item.platform}</TableCell>
                            <TableCell>
                              {item.type === "subscription" &&
                                `${item.frequencyDuration} ${item.frequencyUnit}`}
                              {item.type === "free_tier" && `Illimité`}
                            </TableCell>
                            <TableCell>
                              {new Intl.NumberFormat("fr-FR", {
                                style: "currency",
                                currency: item.currency,
                              }).format(item.price)}
                            </TableCell>
                            <TableCell>{item.trialDuration}</TableCell>
                            <TableCell>{item.resolution}</TableCell>
                            <TableCell>
                              {item.zoneRestrictionStrategy}
                            </TableCell>
                            <TableCell>{item.subscribersCount || 0}</TableCell>
                            <TableCell className="flex space-x-4 items-center">
                              <Button
                                size="xs"
                                variant="light"
                                icon={PencilIcon}
                                onClick={() => {
                                  if (selectedPlanId !== null) {
                                    this.setState({ selectedPlanId: null });
                                    setTimeout(() => {
                                      this.setState({
                                        selectedPlan: item,
                                        selectedPlanId: item.id,
                                      });
                                    }, 500);
                                  } else {
                                    this.setState({
                                      selectedPlan: item,
                                      selectedPlanId: item.id,
                                    });
                                  }
                                }}
                              >
                                Modifier
                              </Button>
                              <Button
                                size="xs"
                                variant="light"
                                color="red"
                                icon={TrashIcon}
                                disabled={loadingPlan || loadingCreate}
                                onClick={() => {
                                  this.onConfirmPlanDelete(item.id);
                                }}
                              >
                                Suppr.
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {selectedPlanId && (
                      <PlanEdit
                        plan={selectedPlan}
                        loading={loadingPlan}
                        editPlan={editPlan}
                        planId={selectedPlanId}
                        onRequestClose={() => {
                          this.setState({ selectedPlanId: null });
                        }}
                      />
                    )}
                  </div>
                </div>

                <hr />

                <Button
                  loading={loading}
                  icon={ArrowRightIcon}
                  iconPosition="right"
                  onClick={() => this.onSubmitForm()}
                >
                  Enregistrer
                </Button>
              </div>
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageEdit;
