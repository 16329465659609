import { connect } from "react-redux";
import {
  getTitleById,
  getEpisodesById,
  getSeasonsById,
  getEpisodeById,
} from "../../selectors/TitleSelectors";
import { getTitle } from "../../actions/title";
import { getEpisodes, editEpisode, getEpisode } from "../../actions/episode";
import { getSeasons } from "../../actions/season";
import component from "../../content/episode/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
  loadingItems: state.entities.loadingCreate,
  title: getTitleById(state, ownProps.match.params.id),
  seasons: getSeasonsById(state, ownProps.match.params.id) || [],
  episodes: getEpisodesById(state, ownProps.match.params.id) || [],
  episode: getEpisodeById(state, ownProps.match.params.epId),
});

const mapDispatchToProps = {
  getTitle,
  getSeasons,
  getEpisodes,
  editEpisode,
  getEpisode,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
