import { connect } from "react-redux";
import { getTitle, publishTitle } from "../../actions/title";
import { getTitleById } from "../../selectors/TitleSelectors";
import component from "../../content/titles/availability";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  title: getTitleById(state, ownProps.match.params.id),
});

const mapDispatchToProps = { getTitle, publishTitle };

export default connect(mapStateToProps, mapDispatchToProps)(component);
