import { connect } from "react-redux";
import { getOffer, editOffer, createPlan } from "../../actions/offer";
import { getOfferById, getPlansByOfferId } from "../../selectors/OfferSelector";
import { getTitles } from "../../actions/title";
import { deletePlan, editPlan, getPlans } from "../../actions/plan";
import component from "../../content/offers/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  loadingPlan: state.entities.loadingPlan,
  loadingCreate: state.entities.loadingCreate,
  titles: state.entities.titles,
  offer: getOfferById(state, ownProps.match.params.id),
  plans: getPlansByOfferId(state, ownProps.match.params.id),
});

const mapDispatchToProps = {
  getTitles,
  getOffer,
  editOffer,
  getPlans,
  createPlan,
  deletePlan,
  editPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(component);
