import { connect } from "react-redux";
import { editCategory, getCategory } from "../../actions/category";
import { getCategoryById } from "../../selectors/CategorySelectors";
import component from "../../content/category/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
  lastEditedId: state.entities.lastEditedId,
  category: getCategoryById(state, ownProps.match.params.id),
});

const mapDispatchToProps = { editCategory, getCategory };

export default connect(mapStateToProps, mapDispatchToProps)(component);
