import { toast } from "react-toastify";
import { offerPlans, planDelete, planEdit } from "../api/offerService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

/**
 * Get plans inside entities
 * @param {*} offerId
 * @param {*} params
 * @returns
 */
export const getPlans =
  (offerId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingPlans", true));
    offerPlans(offerId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingPlans", false));

        let response = result.data;
        const { plans } = response;

        if (plans) {
          let plansByOfferId = { ...state.entities.plansByOfferId };
          plansByOfferId[offerId] = plans;
          dispatch(setKeyValue("plansByOfferId", plansByOfferId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        toast("Impossible de récupérer les prix", { type: "error" });
        dispatch(setKeyValue("loadingPlans", false));
      });
  };

/**
 * Edit a single plan
 * @param {*} planId
 * @param {*} params
 * @returns
 */
export const editPlan =
  (planId, body, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingPlan", true));
    planEdit(planId, body, params)
      .then((result) => {
        dispatch(setKeyValue("loadingPlan", false));

        let response = result.data;
        const { plan } = response;

        if (plan) {
          toast("Modification de votre prix d'abonnement reussi !", {
            type: "success",
          });
          dispatch(getPlans(plan.offerId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        toast("Impossible de modifier votre prix", { type: "error" });
        dispatch(setKeyValue("loadingPlan", false));
      });
  };

/**
 * Get plans inside entities
 * @param {*} offerId
 * @param {*} params
 * @returns
 */
export const deletePlan =
  (planId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingPlan", true));
    planDelete(planId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingPlan", false));

        let response = result.data;
        const { deleted } = response;

        if (deleted) {
          const { offerId } = params;
          if (offerId) dispatch(getPlans(offerId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        toast("Impossible de supprimer votre prix", { type: "error" });
        dispatch(setKeyValue("loadingPlan", false));
      });
  };
