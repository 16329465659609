import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { TextInput } from "@tremor/react";
import { useEffect, useState } from "react";

const DataList = ({
  title,
  items = [],
  selectedRowKeys = [],
  onItemsChecked,
  onItemChecked,
  onSearchText,
}) => {
  const handleSearchText = (evt) => {
    let text = evt.target.value;
    if (onSearchText) onSearchText(text);
  };

  const onCheckedItems = (evt) => {
    let checked = evt.target.checked;
    if (onItemsChecked) onItemsChecked(checked);
  };

  const onCheckedItem = (evt, rowKey) => {
    let checked = evt.target.checked;
    if (onItemChecked) onItemChecked(checked, rowKey);
  };

  return (
    <div>
      <h1 className="font-semibold">{title}</h1>
      <h4 className="text-md text-gray-500">Nbr d'éléments : {items.length}</h4>
      <div className="my-5">
        <TextInput
          placeholder="Rechercher dans la liste"
          onChange={handleSearchText}
          icon={MagnifyingGlassIcon}
        />
      </div>
      <div className="border shadow-sm">
        <div className="px-5 py-5 border-b flex justify-end">
          <input
            type="checkbox"
            class="flex-none focus:ring-none focus:outline-none cursor-pointer accent-tremor-brand dark:accent-dark-tremor-brand"
            onChange={onCheckedItems}
            checked={selectedRowKeys.length === items.length}
          />
        </div>
        <div className="h-[30rem] overflow-y-scroll divide-y">
          {items.map((item, x) => (
            <div class="flex items-center gap-4 p-4" key={x}>
              <img
                alt=""
                class="w-12 h-12 rounded-md object-cover"
                src={item.atworks.find((e) => e.type === "DESKTOP_TITLE")?.url}
              />
              <div class="flex-1 flex flex-col">
                <strong class="text-slate-900 text-sm font-medium dark:text-slate-200">
                  {item.title}
                </strong>
                <span class="text-slate-500 text-sm font-medium dark:text-slate-400">
                  {item.releaseYear}
                </span>
              </div>

              <input
                id={`check-${x}`}
                type="checkbox"
                class="flex-none focus:ring-none focus:outline-none cursor-pointer accent-tremor-brand dark:accent-dark-tremor-brand"
                checked={selectedRowKeys.includes(item.id)}
                onChange={(evt) => {
                  onCheckedItem(evt, item.id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default DataList;
