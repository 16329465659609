/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, SelectItem, TextInput } from "@tremor/react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { addSeason } from "../../actions/season";
import Modal from "../../components/modal";

class SeasonCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seasonName: "",
      seasonPublished: "isPublished",
    };
  }

  onRequestCreate() {
    const { seasonsCount = 1 } = this.props;
    const { seasonName, seasonPublished } = this.state;

    let bodyParams = {
      name: seasonName,
      shortName: `S${seasonsCount + 1}`,
      isPublished: seasonPublished === "isPublished",
      hiddenEpisodeNumbers: false,
    };

    const { addSeason, titleId } = this.props;
    if (addSeason) addSeason(titleId, bodyParams);
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  render() {
    const { loading, title = "Ajouter une saison" } = this.props;

    return (
      <Modal
        title={title}
        buttons={[
          {
            loading,
            content: "Enregistrer",
            type: "primary",
            onClick: () => this.onRequestCreate(),
          },
          {
            content: "Fermer",
            disabled: loading,
            onClick: () => this.onRequestClose(),
          },
        ]}
      >
        <div className="space-y-3 py-5 px-5">
          <div>
            <span className="text-sm text-gray-500">Etat de publication</span>
            <Select
              defaultValue="isPublished"
              placeholder="Sélectionner l'emplacement"
              onValueChange={(seasonPublished) =>
                this.setState({ seasonPublished })
              }
            >
              <SelectItem value="isPublished">Publier directement</SelectItem>
              <SelectItem value="notPublished">Ne pas publier</SelectItem>
            </Select>
          </div>
          <div>
            <span className="text-sm text-gray-500">Titre de la saison</span>
            <TextInput
              icon={DocumentIcon}
              placeholder="Saison 1"
              onChange={(evt) => {
                let seasonName = evt.target.value;
                this.setState({ seasonName });
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
});

const mapDispatchToProps = { addSeason };

export default connect(mapStateToProps, mapDispatchToProps)(SeasonCreate);
