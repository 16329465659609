import {
  ArrowLongRightIcon,
  BookmarkIcon,
  CalculatorIcon,
  CalendarDaysIcon,
  CogIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TicketIcon,
  TvIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  NumberInput,
  Select,
  SelectItem,
  TextInput,
} from "@tremor/react";
import React, { useCallback, useState } from "react";

const PlanEdit = ({ planId, plan, loading, editPlan, onRequestClose }) => {
  const [platform, setPlatform] = useState(plan.platform);
  const [status, setStatus] = useState(plan.status);
  const [price, setPrice] = useState(plan.price);
  const [currency, setCurrency] = useState(plan.currency);
  const [frequency, setFrequency] = useState(plan.frequencyDuration);
  const [resolution, setResolution] = useState(plan.resolution);
  const [trialDuration, setTrialDuration] = useState(plan.trialDuration);
  const [restriction, setRestriction] = useState(plan.zoneRestrictionStrategy);
  const [platformProductId, setPlatformProductId] = useState(
    plan.platformProductId
  );

  const onFormSubmit = useCallback(() => {
    if (price === "") return;
    let bodyParams = {
      platform,
      platformProductId,
      status,
      price,
      currency,
      resolution,
      frequencyDuration: frequency,
      zoneRestrictionStrategy: restriction,
      trialDuration,
    };
    if (editPlan) editPlan(planId, bodyParams);
  }, [
    price,
    platform,
    platformProductId,
    status,
    currency,
    frequency,
    resolution,
    restriction,
    trialDuration,
    editPlan,
    planId,
  ]);

  if (!planId) return null;
  if (!plan) return null;
  return (
    <div className="p-10 space-y-10">
      <div className="grid md:grid-cols-4 gap-5">
        <div className="space-y-5">
          <h1 className="font-semibold text-sm">Commercialisation</h1>
          <div className="space-y-4">
            <div>
              <span className="text-xs text-gray-500">Status</span>
              <Select
                icon={BookmarkIcon}
                placeholder="État de la publication"
                value={status}
                onValueChange={(value) => setStatus(value)}
              >
                <SelectItem value="active">Actif</SelectItem>
                <SelectItem value="inactive">Désactivé</SelectItem>
              </Select>
            </div>

            <div>
              <span className="text-xs text-gray-500">Plateforme</span>
              <Select
                icon={TvIcon}
                value={platform}
                placeholder="Plateforme"
                disabled={plan.type === "free_tier"}
                onValueChange={(value) => setPlatform(value)}
              >
                <SelectItem value="congarts_tv">Congarts</SelectItem>
                <SelectItem value="maxicash">Maxicash</SelectItem>
              </Select>
            </div>

            <div>
              <span className="text-xs text-gray-500">SKU ID</span>
              <TextInput
                disabled={plan.type === "free_tier"}
                placeholder="ID Prix / IAP"
                icon={TicketIcon}
                value={platformProductId}
                onChange={(evt) => {
                  setPlatformProductId(evt.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="space-y-5">
          <h1 className="font-semibold text-sm">
            Tarification & Fréquence de facturation
          </h1>
          <div className="space-y-4">
            <div>
              <span className="text-xs text-gray-500">Dévise</span>
              <Select
                value={currency}
                icon={CurrencyDollarIcon}
                disabled={plan.type === "free_tier"}
                placeholder="État de la publication"
                onValueChange={(value) => setCurrency(value)}
              >
                <SelectItem value="CDF">Franc Congolais</SelectItem>
                <SelectItem value="USD">Dollar Américain</SelectItem>
                <SelectItem value="EUR">Euro</SelectItem>
              </Select>
            </div>

            <div>
              <span className="text-xs text-gray-500">Prix</span>
              <NumberInput
                disabled={plan.type === "free_tier"}
                placeholder="Prix"
                value={`${price}`}
                icon={CalculatorIcon}
                onChange={(evt) => {
                  setPrice(evt.target.value);
                }}
              />
            </div>

            <div>
              <span className="text-xs text-gray-500">Fréquence mensuelle</span>
              <Select
                disabled={plan.type === "free_tier"}
                icon={CalendarDaysIcon}
                placeholder="Période"
                value={`${frequency}`}
                onValueChange={(value) => setFrequency(value)}
              >
                <SelectItem value={"1"}>1 mois</SelectItem>
                <SelectItem value={"3"}>3 mois</SelectItem>
                <SelectItem value={"6"}>6 mois</SelectItem>
                <SelectItem value={"12"}>12 mois</SelectItem>
              </Select>
            </div>
          </div>
        </div>
        <div className="space-y-5">
          <h1 className="font-semibold text-sm">Paramètres</h1>

          <div className="space-y-4">
            <div>
              <span className="text-xs text-gray-500">
                Qualité / Résolution
              </span>
              <Select
                icon={CogIcon}
                placeholder="Résolution"
                value={resolution}
                onValueChange={(value) => setResolution(value)}
              >
                <SelectItem value="SD">SD</SelectItem>
                <SelectItem value="HD">HD</SelectItem>
                <SelectItem value="UHD">UHD</SelectItem>
              </Select>
            </div>

            <div>
              <span className="text-xs text-gray-500">
                Jours d'essai gratuit
              </span>
              <NumberInput
                disabled={plan.type === "free_tier"}
                placeholder="Jours d'essai"
                icon={CalendarDaysIcon}
                min={0}
                max={15}
                value={trialDuration || 0}
                onChange={(evt) => {
                  setTrialDuration(evt.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="space-y-5">
          <h1 className="font-semibold text-sm">Restrictions</h1>
          <div className="space-y-4">
            <div>
              <span className="text-xs text-gray-500">
                Bloquage géographique
              </span>
              <Select
                icon={GlobeAltIcon}
                value={restriction}
                placeholder="Restrictions"
                onValueChange={(value) => setRestriction(value)}
              >
                <SelectItem value="none">Partout</SelectItem>
                <SelectItem value="whitelist">Autoriser des régions</SelectItem>
                <SelectItem value="blacklist">Bloquer des régions</SelectItem>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className="flex space-x-3">
        <Button
          size="xs"
          variant="secondary"
          icon={ArrowLongRightIcon}
          iconPosition="right"
          loading={loading}
          loadingText="Enregistrement en cours"
          onClick={onFormSubmit}
        >
          Enregistrer les modifications
        </Button>
        <Button
          disabled={loading}
          icon={XMarkIcon}
          iconPosition="right"
          variant="secondary"
          color="gray"
          onClick={() => {
            if (onRequestClose) onRequestClose();
          }}
        >
          Fermer
        </Button>
      </div>
    </div>
  );
};

export default PlanEdit;
