import React, { Component } from "react";

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <section className="h-screen">
        <div className="h-full bg-slate-100 flex flex-col justify-center items-center">
          <h1 className="text-4xl leading-tight font-bold">
            Cette page est introuvable dans le système
          </h1>
          <hr />
          <a
            href={"/"}
            className="inline-flex bg-primary hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg
        px-10 py-3 mt-6  disabled:bg-slate-500"
          >
            Retourner
          </a>
        </div>
      </section>
    );
  }
}

export default Error404;
