import { toast } from "react-toastify";
import {
  episodeCreate,
  episodeDelete,
  episodeEdit,
  episodeSingle,
  episodesList,
} from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getEpisodes =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    let episodesById = { ...entities.episodesById };

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    episodesList(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { episodes } = response;

        if (episodes) {
          episodesById[titleId] = episodes;
          dispatch(setKeyValue("episodesById", episodesById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => dispatch(setKeyValue("loading", false)));
  };

export const getEpisode =
  (episodeId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    episodeSingle(episodeId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { episode } = response;

        if (episode) {
          let episodeById = { ...entities.episodeById };
          episodeById[episodeId] = episode;
          dispatch(setKeyValue("episodeById", episodeById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => dispatch(setKeyValue("loading", false)));
  };

export const addEpisode =
  (titleId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    episodeCreate(titleId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { episode } = response;

        if (episode) {
          window.location.replace(`/titles/${titleId}/episodes`);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible d'enregistrer votre épisode", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editEpisode =
  (episodeId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    episodeEdit(episodeId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { episode } = response;

        if (episode) {
          toast("Informations modifiéés", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer les modifications", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const deleteEpisode =
  (episodeId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    episodeDelete(episodeId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { deletedAt } = response;

        if (deletedAt) {
          toast("Vidéo supprimée", {
            position: "top-center",
            type: "success",
          });

          setTimeout(() => window.location.reload(), 1500);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer votre vidéo", {
          position: "top-center",
          type: "error",
        });
      });
  };
