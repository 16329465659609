import React, { Component } from "react";
import { TextInput } from "@tremor/react";
import Modal from "../../components/modal";

class DeleteConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmText: "",
    };
  }

  onRequestConfirm() {
    const { confirmText } = this.state;
    if (confirmText !== "supprimer") return;

    const { onRequestConfirm } = this.props;
    if (onRequestConfirm) onRequestConfirm();
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  render() {
    const { loading, title = "Confirmer suppression" } = this.props;
    return (
      <Modal
        title={title}
        buttons={[
          {
            content: "Supprimer",
            type: "primary",
            loading: loading,
            onClick: () => this.onRequestConfirm(),
          },
          {
            content: "Fermer",
            disabled: loading,
            onClick: () => this.onRequestClose(),
          },
        ]}
      >
        <div className="space-y-3 py-5 px-10">
          <h1 className="font-sm">
            Attention : cette action est irreversible. confirmer la suppression
            en tapant le mot supprimer dans le champs ci-dessous
          </h1>
          <TextInput
            placeholder="Tapez << supprimer >>"
            onChange={(ev) => {
              let confirmText = ev.target.value;
              this.setState({ confirmText });
            }}
          />
        </div>
      </Modal>
    );
  }
}

export default DeleteConfirm;
