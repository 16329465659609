import { combineReducers } from "redux";
import session from "./session/index";
import person from "./person/index";
import account from "./account/index";
import user from "./user/index";
import report from "./report/index";
import access from "./access/index";
import project from "./project/index";
import entities from "./entities/index";
import task from "./task/index";

export default combineReducers({
  session,
  person,
  account,
  report,
  access,
  project,
  entities,
  task,
  user,
});
