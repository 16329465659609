import { toast } from "react-toastify";
import {
  trailerCreate,
  trailerDelete,
  trailersList,
} from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getTrailers =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    let trailersById = { ...entities.trailersById };

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    trailersList(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { trailers } = response;

        if (trailers) {
          trailersById[titleId] = trailers;
          dispatch(setKeyValue("trailersById", trailersById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => dispatch(setKeyValue("loading", false)));
  };

export const addTrailer =
  (titleId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    trailerCreate(titleId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { trailer } = response;

        if (trailer) {
          window.location.replace(`/titles/${titleId}/trailers`);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible d'enregistrer votre contenu", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const deleteTrailer =
  (trailerId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingDelete", true));
    trailerDelete(trailerId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingDelete", false));

        let response = result.data;
        const { deletedAt } = response;

        if (deletedAt) {
          toast("Vidéo supprimée", {
            position: "top-center",
            type: "success",
          });

          setTimeout(() => window.location.reload(), 1500);
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingDelete", false));
        toast("Impossible d'enregistrer votre vidéo", {
          position: "top-center",
          type: "error",
        });
      });
  };
