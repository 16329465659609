import React, { Component } from "react";

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    const { onChange } = this.props;

    let value = e.target.value;
    if (onChange) onChange(value);
  };

  render() {
    const { labelInline, name, label, options = [], ...rest } = this.props;
    return (
      <>
        {label && (
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
        )}
        <select
          {...rest}
          id={name}
          name={name}
          onChange={this.onChange}
          className="form-select w-full mt-2 rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white focus:border-blue-500
focus:bg-white focus:outline-none"
        >
          {options.map((item, index) => (
            <option key={index} value={item.value} disabled={item.disabled}>
              {item.label}
            </option>
          ))}
        </select>
      </>
    );
  }
}

export default Select;
