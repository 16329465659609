import axios from "axios";
import { API_BASE_HOST } from "../constants/api";

const ApiContext = axios.create({
  baseURL: API_BASE_HOST,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "application/json" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/login?logout=auto");
    }
    return Promise.reject(error.response);
  }
);

/**
 * titles
 */

export function titlesList(params = {}) {
  return ApiContext.get(`titles`, { params });
}

export function titleCreate(bodyParams, params = {}) {
  return ApiContext.post(`titles`, bodyParams, { params });
}

export function titleSingle(refId, params = {}) {
  return ApiContext.get(`titles/${refId}`, { params });
}

export function titleEdit(refId, body, params = {}) {
  return ApiContext.patch(`titles/${refId}`, body, { params });
}

export function titlePublish(refId, body, params = {}) {
  return ApiContext.patch(`titles/${refId}/publish`, body, { params });
}

/**
 * categories
 */

export function categoriesList(params = {}) {
  return ApiContext.get(`categories`, { params });
}

export function categoryCreate(bodyParams, params = {}) {
  return ApiContext.post(`categories`, bodyParams, { params });
}

export function categorySingle(refId, params = {}) {
  return ApiContext.get(`categories/${refId}`, { params });
}

export function categoryEdit(refId, body, params = {}) {
  return ApiContext.patch(`categories/${refId}`, body, { params });
}

/**
 * categories
 */

export function atworksList(refId, params = {}) {
  return ApiContext.get(`titles/${refId}/atworks`, { params });
}

export function atworkCreate(refId, bodyParams, params = {}) {
  return ApiContext.post(`titles/${refId}/atworks`, bodyParams, { params });
}

/**
 * categories
 */

export function trailersList(refId, params = {}) {
  return ApiContext.get(`titles/${refId}/trailers`, { params });
}

export function trailerCreate(refId, bodyParams, params = {}) {
  return ApiContext.post(`titles/${refId}/trailers`, bodyParams, { params });
}

export function trailerEdit(refId, bodyParams, params = {}) {
  return ApiContext.patch(`trailers/${refId}`, bodyParams, { params });
}

export function trailerDelete(refId, bodyParams, params = {}) {
  return ApiContext.delete(`trailers/${refId}`, bodyParams, { params });
}

/**
 * seasons
 */

export function seasonsList(refId, params = {}) {
  return ApiContext.get(`titles/${refId}/seasons`, { params });
}

export function seasonCreate(refId, bodyParams, params = {}) {
  return ApiContext.post(`titles/${refId}/seasons`, bodyParams, { params });
}

export function seasonEdit(refId, bodyParams, params = {}) {
  return ApiContext.patch(`seasons/${refId}`, bodyParams, { params });
}

export function seasonDelete(refId, bodyParams, params = {}) {
  return ApiContext.delete(`trailers/${refId}`, bodyParams, { params });
}

/**
 * episodes
 */

export function episodesList(refId, params = {}) {
  return ApiContext.get(`titles/${refId}/episodes`, { params });
}

export function episodeCreate(refId, bodyParams, params = {}) {
  return ApiContext.post(`titles/${refId}/episodes`, bodyParams, { params });
}

export function episodeSingle(refId, params = {}) {
  return ApiContext.get(`episodes/${refId}`, { params });
}

export function episodeEdit(refId, bodyParams, params = {}) {
  return ApiContext.patch(`episodes/${refId}`, bodyParams, { params });
}

export function episodeDelete(refId, bodyParams, params = {}) {
  return ApiContext.delete(`episodes/${refId}`, bodyParams, { params });
}

/**
 * movies
 */

export function moviesList(refId, params = {}) {
  return ApiContext.get(`titles/${refId}/movie`, { params });
}

export function movieCreate(refId, bodyParams, params = {}) {
  return ApiContext.post(`titles/${refId}/movie`, bodyParams, { params });
}

export function movieDelete(refId, bodyParams, params = {}) {
  return ApiContext.delete(`movies/${refId}`, bodyParams, { params });
}
