import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, TextInput, Select, SelectItem } from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import {
  ArrowRightIcon,
  DocumentIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import TitleMenu from "../titles/sidemenu";
import VideoUploader from "../../components/upload/video";
import { captureVideoFrame, getVideoMetadata } from "../../utils/VideoUtil";
import { getSignedUrl } from "../../api/assetService";

class PageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublished: "isPublished",
      episodeSaisonId: null,
      episodeTitle: "",
      contextualSynopsis: "",
      assetUrl: null,
      assetUri: null,
      posterAtworks: [],
      videoDuration: null,
      videoHeight: null,
      videoWidth: null,
      videoType: null,
      blobUrl: null,
    };
    this.video = document.createElement("video");
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getSeasons, getTitle, match, title } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getSeasons) getSeasons(match.params.id, {});
  }

  onSubmitForm() {
    const {
      posterAtworks,
      assetUri,
      assetUrl,
      videoDuration,
      videoHeight,
      isPublished,
    } = this.state;

    if (assetUri == null || assetUrl == null) {
      toast("Aucune vidéo n'a été téléchargée", { type: "error" });
      return;
    }

    let bodyParams = {
      isPublished: isPublished === "isPublished",
      posterAtworks,
      asset: {
        url: assetUri,
        duration: videoDuration,
        resolutionTier: `${videoHeight}p`,
      },
    };

    const { match, addMovie } = this.props;
    if (addMovie) addMovie(match.params.id, bodyParams);
  }

  handleFile = (file) => {
    getVideoMetadata(file)
      .then((data) => {
        console.log("loaded metadata", data);

        this.setState({
          videoHeight: data.videoHeight,
          videoWidth: data.videoWidth,
          videoDuration: data.duration,
          videoType: data.type,
        });
      })
      .catch((err) => {
        toast("Impossible de récupérer les infos de la vidéo", {
          type: "error",
        });
      });
  };

  handleFromBlob = (blob) => {
    if (this.state.posterAtworks.length === 5) return;

    let _context = this;
    let url = (window.URL || window.webkitURL).createObjectURL(blob);

    var onSeeked = function () {
      console.log("onTimeUpdate", video.currentTime);
      _context.takeSnapshot(video);

      window.removeEventListener("seeked", onSeeked);
      (window.URL || window.webkitURL).revokeObjectURL(url);
    };

    var video = document.createElement("video");
    video.src = url;
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      console.log("video.duration: ", video.duration);
      console.log("video.width: ", video.width);
      console.log("video.height: ", video.height);

      video.currentTime = video.duration * 0.35;
    };
    video.addEventListener("seeked", onSeeked);
    video.muted = true;
    video.autoplay = true;
    video.playsInline = true;
  };

  takeSnapshot = async (elementOrId = "video-preview") => {
    try {
      const frame = captureVideoFrame(elementOrId, "jpeg");

      const { data: presignedUrl } = await getSignedUrl({
        fileName: `video-frame-${new Date().getTime()}.${frame.format}`,
        contentType: "image/jpeg",
      });

      const { url, publicUrl } = presignedUrl;
      const { data, headers } = await axios.put(url, frame.blob, {
        headers: { "Content-Type": "image/jpeg" },
      });

      console.log({ presignedUrl, data, headers });

      let posterAtworks = [...this.state.posterAtworks, publicUrl];
      this.setState({ posterAtworks });
    } catch (error) {
      console.log("takeSnapshot [error]", error);
    }
  };

  render() {
    const { assetUri, posterAtworks } = this.state;
    const { match, seasons, title, loading } = this.props;

    const buttonDisabled = assetUri == null || posterAtworks.length === 0;
    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={3} />
          <PageLayout title={"Télécharger votre film"}>
            <div className="py-10">
              <div className="flex flex-row space-x-10">
                <div className="w-full md:w-1/2 space-y-3">
                  <VideoUploader
                    handleFile={this.handleFile}
                    handleFromBlob={this.handleFromBlob}
                    onProgress={(p) => this.setState({ uploadProgress: p })}
                    handleUri={(assetUri) => this.setState({ assetUri })}
                    handleUrl={(assetUrl) => this.setState({ assetUrl })}
                  />

                  <div>
                    <span className="text-sm text-gray-500">
                      Etat de la publication
                    </span>
                    <Select
                      icon={Square2StackIcon}
                      defaultValue="isPublished"
                      placeholder="Sélectionner l'état de la publication"
                      onValueChange={(isPublished) =>
                        this.setState({ isPublished })
                      }
                    >
                      <SelectItem value="isPublished">Rendre public</SelectItem>
                      <SelectItem value="notPublished">
                        Ne pas publier
                      </SelectItem>
                    </Select>
                  </div>

                  <hr />
                  <Button
                    iconPosition="right"
                    icon={ArrowRightIcon}
                    loading={loading}
                    disabled={buttonDisabled}
                    onClick={() => this.onSubmitForm()}
                  >
                    Enregistrer le film
                  </Button>
                </div>

                <div className="w-full md:w-1/2 space-y-3">
                  <video
                    preload="none"
                    id={"video-preview"}
                    style={{ display: "none", marginTop: 10 }}
                  />
                </div>
              </div>
            </div>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageCreate;
