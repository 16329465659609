import React, { Component } from "react";
import { Button, Card } from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import {
  TrashIcon,
  CloudArrowUpIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import TitleMenu from "../titles/sidemenu";
import DeleteConfirm from "../../components/feedback/deleteModal";

class PageTrailers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTrailerId: null,
      modalDeleteVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getTrailers, getTitle, match, title } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getTrailers) getTrailers(match.params.id, {});
  }

  render() {
    const { modalDeleteVisible } = this.state;
    const { trailers, match, history, loading, title, loadingDelete } =
      this.props;
    const titleId = match.params.id;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={2} />
          <PageLayout title={"Bande d'annonces"}>
            <div className="py-10">
              <Button
                loading={loading}
                variant="secondary"
                icon={CloudArrowUpIcon}
                iconPosition="right"
                size="xs"
                onClick={() => {
                  history.push(`/titles/${titleId}/trailers/upload`);
                }}
              >
                Ajouter une vidéo
              </Button>

              <hr className="my-5" />
              <div className="grid grid-cols-3 2xl:grid-cols-4 gap-5">
                {trailers.map((item, x) => (
                  <Card key={x} className="space-y-3">
                    <div className="h-[8rem] relative">
                      <img
                        alt={item.title}
                        src={item.posterAtworks[0]}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 flex flex-col justify-center items-center">
                        <a href="javascript:void(0);" onClick={() => {}}>
                          <div className="w-12 h-12 rounded-full bg-white flex flex-col justify-center items-center">
                            <PlayIcon className="w-5 h-5 text-black" />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div>
                      <span className="text-sm">{item.title}</span>
                    </div>

                    <Button
                      size="xs"
                      variant="secondary"
                      color={"red"}
                      icon={TrashIcon}
                      onClick={() => {
                        this.setState({
                          selectedTrailerId: item.id,
                          modalDeleteVisible: true,
                        });
                      }}
                    >
                      Supprimer la vidéo
                    </Button>
                  </Card>
                ))}
              </div>
            </div>
          </PageLayout>
        </div>

        {modalDeleteVisible && (
          <DeleteConfirm
            loading={loadingDelete}
            onRequestConfirm={() => this.onRequestDelete()}
            onRequestClose={() => {
              this.setState({ modalDeleteVisible: false });
            }}
          />
        )}
      </div>
    );
  }

  onRequestDelete() {
    const { selectedTrailerId } = this.state;
    const { deleteTrailer } = this.props;

    if (deleteTrailer) deleteTrailer(selectedTrailerId);
  }
}

export default PageTrailers;
