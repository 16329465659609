import React, { Component } from "react";

class ProgressCircular extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div class="progress-circle-indeterminate"></div>;
  }
}

export default ProgressCircular;
