import React, { Component } from "react";
import PropTypes from "prop-types";
import TableColumn from "./column";
import TableRow from "./row";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static defaultProps = {
    columns: [],
    dataSource: [],
  };

  static propTypes = {
    columns: PropTypes.array,
    dataSource: PropTypes.array,
  };

  render() {
    const { columns, dataSource } = this.props;
    return (
      <div className="w-full">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full shadow-sm border rounded-md">
                  <thead className="bg-gray-100 border-b border-b-gray-200 border-t border-t-gray-200">
                    <tr>
                      {columns.map((item, index) => (
                        <TableColumn key={index} {...item} />
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Product 1 */}
                    {dataSource.map((item, index) => (
                      <TableRow key={index} columns={columns} row={item} />
                    ))}
                    {/* Product 2 */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
