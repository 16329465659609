import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  TextInput,
  Select,
  SelectItem,
  Card,
  TabPanels,
  TabGroup,
  TabList,
  TabPanel,
  Tab,
} from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import {
  ArrowRightIcon,
  CloudArrowUpIcon,
  DocumentIcon,
  LinkIcon,
  Square2StackIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import TitleMenu from "../titles/sidemenu";
import VideoUploader from "../../components/upload/video";
import Atwork from "../atwork/item";
import RichText from "../../components/form/richtext";
import LinkUploader from "../../components/upload/link";
import { getVideoMetadata } from "../../utils/VideoUtil";

class PageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      episodeId: null,
      episodePublished: "isPublished",
      episodeSaisonId: null,
      episodeTitle: "",
      contextualSynopsis: "",
      assetKind: "hosted",
      assetPlatform: null,
      assetUrl: null,
      assetUri: null,
      posterAtworks: [],
      videoDuration: null,
      videoHeight: null,
      videoWidth: null,
      videoType: null,
      blobUrl: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps, prevState) {
    const { episode } = this.props;
    if (episode) {
      if (prevState.episodeId !== episode.id) {
        this.setState({
          episodePublished: episode.isPublished
            ? "isPublished"
            : "notPublished",
          episodeSaisonId: episode.seasonId,
          episodeTitle: episode.title,
          contextualSynopsis: episode.contextualSynopsis,
          posterAtworks: episode.posterAtworks,
          episodeId: episode.id,
        });
      }
    }
  }

  fetchDataIfNeeded() {
    const { getSeasons, getTitle, getEpisode, match, title } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getSeasons) getSeasons(match.params.id, {});
    if (getEpisode) getEpisode(match.params.epId, {});
  }

  onSubmitForm() {
    const {
      episodeId,
      episodeSaisonId,
      episodeTitle,
      videoDuration,
      videoHeight,
      contextualSynopsis,
      episodePublished,
      posterAtworks,
      assetUri,
      assetUrl,
      assetKind,
      assetPlatform,
    } = this.state;

    if (
      episodeTitle === "" ||
      contextualSynopsis === "" ||
      episodeSaisonId === null
    ) {
      toast("Veuillez remplir tous les champs", { type: "error" });
      return;
    }

    let bodyParams = {
      seasonId: episodeSaisonId,
      title: episodeTitle,
      contextualSynopsis,
      isPublished: episodePublished === "isPublished",
      posterAtworks,
      asset: null,
    };

    if (assetUri) {
      bodyParams.asset = {
        uri: assetUri,
        kind: assetKind,
        duration: videoDuration,
        sourceAssetId: assetUrl,
        platformAssetId: assetPlatform,
        resolutionTier: videoHeight && `${videoHeight}p`,
      };
    }

    const { editEpisode } = this.props;
    if (editEpisode) editEpisode(episodeId, bodyParams);
  }

  handleFile = (file) => {
    getVideoMetadata(file)
      .then((data) => {
        console.log("loaded metadata", data);

        this.setState({
          videoHeight: data.videoHeight,
          videoWidth: data.videoWidth,
          videoDuration: data.duration,
          videoType: data.type,
        });
      })
      .catch((err) => {
        toast("Impossible de récupérer les infos de la vidéo", {
          type: "error",
        });
      });
  };

  render() {
    const {
      episodeSaisonId,
      episodeTitle,
      episodePublished,
      contextualSynopsis,
      posterAtworks,
    } = this.state;
    const { episode, seasons, title, loading } = this.props;

    const buttonDisabled = episodeSaisonId == null || episodeTitle === "";
    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={3} />
          <PageLayout title={"Modifier une épisode"}>
            <div className="py-10">
              <Card>
                <div className="space-y-5">
                  <div className="md:w-5/12 space-y-3">
                    <div>
                      <span className="text-sm text-gray-500">
                        Etat de la publication
                      </span>
                      <Select
                        icon={Square2StackIcon}
                        value={episodePublished}
                        defaultValue="isPublished"
                        placeholder="Sélectionner l'état de la publication"
                        onValueChange={(episodePublished) =>
                          this.setState({ episodePublished })
                        }
                      >
                        <SelectItem value="isPublished">
                          Rendre public
                        </SelectItem>
                        <SelectItem value="notPublished">
                          Ne pas publier
                        </SelectItem>
                      </Select>
                    </div>

                    <div>
                      <span className="text-sm text-gray-500">Saison *</span>
                      <Select
                        icon={Squares2X2Icon}
                        value={episodeSaisonId}
                        placeholder="Sélectionner la saison"
                        onValueChange={(episodeSaisonId) =>
                          this.setState({ episodeSaisonId })
                        }
                      >
                        {seasons.map((item) => (
                          <SelectItem value={item.id}>{item.name}</SelectItem>
                        ))}
                      </Select>
                    </div>

                    <div>
                      <span className="text-sm text-gray-500">
                        Titre de l'épisode *
                      </span>
                      <TextInput
                        icon={DocumentIcon}
                        placeholder="Titre"
                        value={episodeTitle}
                        defaultValue={episode?.title}
                        onChange={(evt) => {
                          let episodeTitle = evt.target.value;
                          this.setState({ episodeTitle });
                        }}
                      />
                    </div>

                    <RichText
                      nameOrId={"contextualSynopsis"}
                      label="Description de l'épisode *:"
                      placeholder="Entrez votre description ici..."
                      value={contextualSynopsis}
                      defaultValue={episode?.contextualSynopsis}
                      onChange={(evt) => {
                        let contextualSynopsis = evt.target.value;
                        this.setState({ contextualSynopsis });
                      }}
                    />
                  </div>

                  <hr />
                  <div className="flex-1 space-y-3">
                    <h1 className="font-semibold text-md">
                      Changer l'illustration
                    </h1>
                  </div>

                  <div className="w-[30rem] h-[15rem]">
                    <Atwork
                      textAlt="Illustration 16/9"
                      photo={{ url: posterAtworks[0] }}
                      onImageUploaded={(info, publicUrl) => {
                        this.setState({ posterAtworks: [publicUrl] });
                      }}
                    />
                  </div>

                  <hr />

                  <div className="md:w-5/12 space-y-3">
                    <h1 className="font-semibold text-md">
                      Remplacer la vidéo
                    </h1>

                    <TabGroup>
                      <TabList className="mt-8">
                        <Tab
                          icon={CloudArrowUpIcon}
                          onClick={() => this.setState({ assetKind: "hosted" })}
                        >
                          Uploader une vidéo
                        </Tab>
                        <Tab icon={LinkIcon}>Ajouter depuis un lien</Tab>
                      </TabList>
                      <TabPanels className="py-5">
                        <TabPanel>
                          <VideoUploader
                            handleFile={this.handleFile}
                            handleUri={(assetUri) =>
                              this.setState({ assetUri })
                            }
                            handleUrl={(assetUrl) =>
                              this.setState({ assetUrl })
                            }
                          />
                          <video
                            id={"video-preview"}
                            preload="none"
                            style={{ display: "none", marginTop: 10 }}
                          />
                        </TabPanel>
                        <TabPanel>
                          <LinkUploader
                            onLinked={({
                              assetUrl,
                              assetUri,
                              assetPlatform,
                              videoDuration,
                            }) => {
                              this.setState({
                                assetUrl,
                                assetUri,
                                assetPlatform,
                                videoDuration,
                                assetKind: "external",
                              });
                            }}
                          />
                        </TabPanel>
                      </TabPanels>
                    </TabGroup>
                  </div>

                  <hr />
                  <Button
                    iconPosition="right"
                    icon={ArrowRightIcon}
                    loading={loading}
                    disabled={buttonDisabled}
                    onClick={() => this.onSubmitForm()}
                  >
                    Enregistrer les modifications
                  </Button>
                </div>
              </Card>
            </div>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageEdit;
