import moment from "moment";
import React, { Component } from "react";
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  Button,
  Card,
} from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import { TvIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import AtworkAdd from "./modalcreate";
import { atworkTypeTexts } from "../../constants/texts";
import TitleMenu from "../titles/sidemenu";
import Atwork from "./item";

class PageAtworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaCategory: "TV_DESKTOP",
      modalVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getAtworks, getTitle, getEpisode, title, match } = this.props;
    if (!title && getTitle) getTitle(match.params.id, {});
    if (getAtworks) getAtworks(match.params.id, {});
    if (getEpisode) getEpisode(match.params.id, {});
  }

  onImageUploaded = (atworkType, publicUrl) => {
    let bodyParams = {
      type: atworkType,
      url: publicUrl,
    };

    const { addAtwork, match } = this.props;
    if (addAtwork) addAtwork(match.params.id, bodyParams);
  };

  render() {
    const { modalVisible, mediaCategory } = this.state;
    const { title, atworks, addAtwork, match, loadingCreate, loading } =
      this.props;
    const titleId = match.params.id;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu selectedMenuIndex={1} title={title} />
          <PageLayout title={"Illustrations de votre titre"}>
            <TabGroup>
              <TabList className="mt-8">
                <Tab icon={TvIcon}>TV & Ordinateurs</Tab>
                <Tab icon={DevicePhoneMobileIcon}>Devices Mobiles</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <div className="py-10">
                    <div className="flex space-x-10">
                      <div className="flex-1 h-[25rem]">
                        <Atwork
                          viewMode="cover"
                          textAlt="Couverture large"
                          location={"DESKTOP_COVER"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find(
                            (e) => e.type === "DESKTOP_COVER"
                          )}
                        />
                      </div>
                      <div className="w-[30%] h-[18rem]">
                        <Atwork
                          viewMode="cover"
                          textAlt="Image promo"
                          location={"DESKTOP_TITLE"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find(
                            (e) => e.type === "DESKTOP_TITLE"
                          )}
                        />
                      </div>
                      <div className="w-[25%] h-[15rem]">
                        <Atwork
                          textAlt="Logo"
                          location={"TITLE_ALPHA"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find((e) => e.type === "TITLE_ALPHA")}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="py-10">
                    <div className="flex space-x-10">
                      <div className="w-[40%] h-[25rem]">
                        <Atwork
                          viewMode="cover"
                          textAlt="Couverture large"
                          location={"MOBILE_COVER"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find((e) => e.type === "MOBILE_COVER")}
                        />
                      </div>
                      <div className="w-[15%] h-[25rem]">
                        <Atwork
                          viewMode="cover"
                          textAlt="Image promo"
                          location={"MOBILE_TITLE"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find((e) => e.type === "MOBILE_TITLE")}
                        />
                      </div>
                      <div className="w-[25%] h-[15rem]">
                        <Atwork
                          textAlt="Logo"
                          location={"TITLE_ALPHA"}
                          onImageUploaded={this.onImageUploaded}
                          photo={atworks.find((e) => e.type === "TITLE_ALPHA")}
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </PageLayout>
        </div>
        {modalVisible && (
          <AtworkAdd
            titleId={titleId}
            addAtwork={addAtwork}
            mediaCategory={mediaCategory}
            loading={loadingCreate}
            onRequestClose={() =>
              this.setState({
                modalVisible: false,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default PageAtworks;
