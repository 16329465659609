import { connect } from "react-redux";
import { getTitle, editTitle } from "../../actions/title";
import { getCategories } from "../../actions/category";
import { getTitleById } from "../../selectors/TitleSelectors";
import component from "../../content/titles/edit";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  categories: state.entities.categories || [],
  title: getTitleById(state, ownProps.match.params.id),
});

const mapDispatchToProps = { getTitle, editTitle, getCategories };

export default connect(mapStateToProps, mapDispatchToProps)(component);
