/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import { slugify, uriFileExtension, uriFileName } from "../../utils/TextUtil";
import { getSignedUrl } from "../../api/assetService";
import { toast } from "react-toastify";
import axios from "axios";
import ProgressIndicator from "../../components/progress/circular";
import { classNames } from "../../helpers/classNames";

const Atwork = ({
  photo,
  textAlt = "",
  viewMode = "contain",
  location,
  onImageUploaded,
}) => {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  const onFileChange = async (evt) => {
    let files = evt.target.files;
    let [firstFile] = files;

    const { name: fileName, type: fileType } = firstFile;

    let _fileName = slugify(uriFileName(fileName));
    let _fileExt = uriFileExtension(fileName);

    inputRef.current.value = null;

    try {
      setLoading(true);
      const {
        data: { url, publicUrl },
      } = await getSignedUrl({
        fileName: `${new Date().getTime()}_${_fileName}.${_fileExt}`,
        contentType: fileType,
      });

      const { data, headers } = await axios.put(url, firstFile, {
        headers: { "Content-Type": fileType },
      });

      console.log("upload signed", headers);
      setLoading(false);

      if (onImageUploaded) onImageUploaded(location, publicUrl);
    } catch (error) {
      setLoading(false);
      toast("Echec du téléchargement de l'image", { type: "error" });
    }
  };

  return (
    <>
      <a
        href="javascript:void(0);"
        onClick={() => {
          if (loading) return;
          if (inputRef) inputRef.current.click();
        }}
      >
        <div className="h-full w-full border-[0.5px] flex flex-col overflow-hidden relative">
          {!photo && (
            <div className="flex-1 flex flex-col justify-center items-center space-y-3 bg-slate-200">
              <CloudArrowUpIcon className="w-6 h-6 text-gray-500" />
              <span className="text-center text-sm text-gray-500">
                {textAlt}
              </span>
            </div>
          )}
          {photo && (
            <img
              alt={location}
              src={photo.url}
              className={classNames(
                "w-full",
                viewMode === "cover" ? "object-cover" : "object-contain"
              )}
            />
          )}
          {loading && (
            <div className="absolute inset-0 flex flex-col justify-end items-center py-5">
              <ProgressIndicator />
            </div>
          )}
        </div>
      </a>

      <input
        ref={inputRef}
        className="hidden"
        type={"file"}
        accept={"image/png,image/jpeg,image/pjpeg"}
        onChange={onFileChange}
      />
    </>
  );
};

export default Atwork;
