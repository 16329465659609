import axios from "axios";
import { API_BASE_PROD, API_BASE_DEV } from "../constants/api";

const ApiContext = axios.create({
  baseURL: API_BASE_PROD,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "text/plain" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/?logout=auto");
    }
    return Promise.reject(error.response);
  }
);

export function authenticateUser(bodyParams, params = {}) {
  return ApiContext.post(`session/token`, bodyParams, {
    params,
  });
}
