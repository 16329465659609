import React, { Component } from "react";

class PageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, title, subTitle } = this.props;
    return (
      <div className="w-full py-10">
        <div className="min-h-full bg-white rounded-lg p-10 mb-10 2xl:px-20">
          <div className="flex justify-between border-b border-gray-200 pb-5 mb-5">
            <div className="w-1/2">
              <h2 className="text-3xl font-bold">{title}</h2>
              <h4>{subTitle}</h4>
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default PageLayout;
