import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Error404 from "../containers/error/404";

import AuthLogin from "../containers/auth/index";
import PageDashboard from "../containers/dashboard/index";

import CategoryList from "../containers/category/index";
import CategoryCreate from "../containers/category/create";
import CategoryEdit from "../containers/category/edit";

import OffersList from "../containers/offers/index";
import OfferCreate from "../containers/offers/create";
import OfferEdit from "../containers/offers/edit";

import TitleList from "../containers/title/index";
import TitleCreate from "../containers/title/create";
import TitleSingle from "../containers/title/single";
import TitleEdit from "../containers/title/edit";
import TitleAvailability from "../containers/title/availability";
import TitleAtworks from "../containers/atwork/index";
import TitleTrailers from "../containers/trailers/index";
import TitleTrailerUpload from "../containers/trailers/upload";
import TitleEpisodes from "../containers/episode/index";
import TitleEpisodeCreate from "../containers/episode/create";
import TitleEpisodeEdit from "../containers/episode/edit";
import TitleMovie from "../containers/movie/index";
import TitleMovieUpload from "../containers/movie/upload";

class BaseRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path="/" component={AuthLogin} />

        <Route exact path="/login" component={AuthLogin} />

        <Route exact path="/dashboard" component={PageDashboard} />
        <Route exact path="/dashboard/create" component={PageDashboard} />

        <Route exact path="/categories" component={CategoryList} />
        <Route exact path="/categories/create" component={CategoryCreate} />
        <Route exact path="/categories/edit/:id" component={CategoryEdit} />

        <Route exact path="/offers" component={OffersList} />
        <Route exact path="/offers/create" component={OfferCreate} />
        <Route exact path="/offers/edit/:id" component={OfferEdit} />
        <Route exact path="/offers/:id" component={OffersList} />

        <Route exact path="/titles" component={TitleList} />
        <Route exact path="/titles/create" component={TitleCreate} />
        <Route exact path="/titles/:id/edit" component={TitleEdit} />
        <Route exact path="/titles/:id/medias" component={TitleSingle} />
        <Route exact path="/titles/:id/atworks" component={TitleAtworks} />
        <Route exact path="/titles/:id/seasons" component={TitleSingle} />
        <Route exact path="/titles/:id/episodes" component={TitleEpisodes} />
        <Route exact path="/titles/:id/episodes/new" component={TitleEpisodeCreate} />
        <Route exact path="/titles/:id/episodes/:epId" component={TitleEpisodeEdit} />
        <Route exact path="/titles/:id/documents" component={TitleSingle} />
        <Route exact path="/titles/:id/movie" component={TitleMovie} />
        <Route exact path="/titles/:id/movie/upload" component={TitleMovieUpload} />
        <Route exact path="/titles/:id/trailers" component={TitleTrailers} />
        <Route exact path="/titles/:id/trailers/upload" component={TitleTrailerUpload} />
        <Route exact path="/titles/:id/availability" component={TitleAvailability} />
        <Route exact path="/titles/:id" component={TitleSingle} />

        <Route component={Error404} />
      </Switch>
    );
  }
}

export default BaseRouter;
