import React from "react";
import SideMenu from "../../components/sidebar/menu";
import {
  DocumentCheckIcon,
  FolderIcon,
  ListBulletIcon,
  PencilSquareIcon,
  PhotoIcon,
  PlusCircleIcon,
  SignalIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

function TitleMenu({ title, selectedMenuIndex = 0 }) {
  if (!title) return null;
  return (
    <SideMenu
      selectedMenuIndex={selectedMenuIndex}
      menuItems={[
        {
          text: "Informations",
          title: "Informations",
          href: `/titles/${title.id}`,
          icon: <ListBulletIcon className="w-4 h-4" />,
        },
        {
          text: "Illustrations",
          title: "Illustrations",
          href: `/titles/${title.id}/atworks`,
          icon: <PhotoIcon className="w-4 h-4" />,
        },
        {
          text: "Bande d'annonces",
          title: "Bande d'annonces",
          href: `/titles/${title.id}/trailers`,
          icon: <VideoCameraIcon className="w-4 h-4" />,
        },
        title.contentType === "episodic"
          ? {
              text: "Episodes",
              title: "Episodes",
              href: `/titles/${title.id}/episodes`,
              icon: <PlusCircleIcon className="w-4 h-4" />,
            }
          : {
              text: "Votre Film",
              title: "Votre Film",
              href: `/titles/${title.id}/movie`,
              icon: <PlusCircleIcon className="w-4 h-4" />,
            },
        {
          text: "Documents & Copyright",
          title: "Documents & Copyright",
          href: `/titles/${title.id}/medias`,
          icon: <DocumentCheckIcon className="w-4 h-4" />,
        },
        {
          text: "Modifier",
          title: "Modifier",
          href: `/titles/${title.id}/edit`,
          icon: <PencilSquareIcon className="w-4 h-4" />,
        },
        {
          text: "Publication",
          title: "Publication",
          href: `/titles/${title.id}/availability`,
          icon: <SignalIcon className="w-4 h-4" />,
        },
      ]}
    />
  );
}

export default TitleMenu;
