import { authenticateUser } from "../api/authService";
import { setCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "SESSION_SET_VALUE",
  propKey,
  propValue,
});

export const loginUser = (bodyParams) => (dispatch, getState) => {
  const state = getState();
  const { live } = state;

  dispatch(setKeyValue("loading", true));
  authenticateUser(bodyParams)
    .then((result) => {
      dispatch(setKeyValue("loading", false));

      let response = result.data;
      const { access_token, associated_user, error_type } = response;
      if (error_type || error_type != null) {
        if (error_type === "account_invalid ") {
          alert("Aucun compte trouvé pour cet identifiant");
        } else if (error_type === "password_invalid ") {
          alert("Le mot de passe fourni est invalide");
        } else if (error_type === "account_disabled ") {
          alert(
            "Votre compte est desactivé, veuillez contacter votre administrateur"
          );
        } else {
          alert(
            "Echec de connexion à votre compte, veuillez contacter votre administrateur"
          );
        }
      } else if (access_token) {
        setCookie("access_token", access_token);
        setCookie("account_username", associated_user.username);
        setCookie("account_id", associated_user.id);
        setCookie("display_name", associated_user.display_name);
        setCookie("user_role", associated_user.user_role);
        setCookie("base_location", JSON.stringify(associated_user.location));

        window.location.replace("/dashboard");
      } else {
        throw new Error("error");
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(setKeyValue("loading", false));
      alert("echec de connexion");
    });
};
