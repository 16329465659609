import { toast } from "react-toastify";
import { atworkCreate, atworksList } from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getAtworks =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    let atworksById = { ...entities.atworksById };

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    atworksList(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { atworks } = response;

        if (atworks) {
          atworksById[titleId] = atworks;
          dispatch(setKeyValue("atworksById", atworksById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => dispatch(setKeyValue("loading", false)));
  };

export const addAtwork =
  (titleId, bodyParams, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    atworkCreate(titleId, bodyParams, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { atwork } = response;

        if (atwork) {
          toast("Image enregistrée !", { type: "success" });
          dispatch(getAtworks(titleId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossible d'enregistrer votre image", {
          position: "top-center",
          type: "error",
        });
      });
  };
