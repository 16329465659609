import React, { Component } from "react";

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      children,
      size = "small",
      onClick = null,
      disabled = false,
    } = this.props;
    return (
      <>
        {size == "small" && (
          <button
            disabled={disabled}
            type="button"
            className="bg-white shadow-sm text-gray-900 cursor-pointer group relative border rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium hover:bg-gray-50 focus:outline-none sm:flex-1"
            onClick={onClick}
          >
            {children}
          </button>
        )}
        {size == "large" && (
          <button
            disabled={disabled}
            type="button"
            className="bg-white shadow-sm text-gray-900 cursor-pointer group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1"
            onClick={onClick}
          >
            {children}
          </button>
        )}
      </>
    );
  }
}

export default Button;
