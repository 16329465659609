/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import Modal from "../../components/modal";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import { Badge, Select, SelectItem } from "@tremor/react";
import { getSignedUrl, presignedPost } from "../../api/assetService";
import { slugify, uriFileExtension, uriFileName } from "../../utils/TextUtil";

class ModalCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingUpload: false,
      atworkType: null,
      atworkUrl: null,
      isUploaded: false,
    };
  }

  onFileChange = (e) => {
    let files = e.target.files;
    let [firstFile] = files;

    console.log(firstFile);
    const { name: fileName, type: fileType, size: imageSize } = firstFile;

    this.setState({ fileName, fileType, imageSize });

    let uploader = document.getElementById("file-upload");
    uploader.value = null;

    let _fileName = slugify(uriFileName(fileName));
    let _fileExt = uriFileExtension(fileName);

    this.setState({ loadingUpload: true });
    getSignedUrl({
      fileName: `${new Date().getTime()}_${_fileName}.${_fileExt}`,
      contentType: fileType,
    })
      .then((result) => {
        let response = result.data;
        console.log("response:", response);

        const { url, publicUrl, fields } = response;
        if (url) {
          this.setState({ atworkUrl: publicUrl }, () => {
            this.putImage(url, firstFile);
          });
          // this.uploadImage(url, fields, firstFile);
        } else {
          throw new Error("cant retrieve signed url");
        }
      })
      .catch((err) => {
        this.setState({ loadingUpload: false });
        console.log("err:", err);

        toast("Impossible de charger votre image", {
          type: "error",
          position: "top-center",
        });
      });
  };

  uploadImage(url, fields, file) {
    const headers = { "Content-Type": "multipart/form-data" };
    const form = new FormData();
    Object.entries(fields).forEach(([field, value]) => {
      form.append(field, value);
    });
    form.append("file", file);

    axios
      .post(url, form, { headers })
      .then((result) => {
        this.setState({ loadingUpload: false, isUploaded: true });
        toast("Téléchargement de votre image réussie ", {
          type: "success",
          position: "top-center",
        });
      })
      .catch((err) => {
        this.setState({ loadingUpload: false });
        console.log("err:", err);

        toast("Impossible de charger votre image", {
          type: "error",
          position: "top-center",
        });
      });
  }

  putImage(url, file) {
    const headers = {
      "Content-Type": file.type,
    };
    axios
      .put(url, file, { headers })
      .then((result) => {
        this.setState({ loadingUpload: false, isUploaded: true });
        toast("Téléchargement de votre image réussie ", {
          type: "success",
          position: "top-center",
        });
      })
      .catch((err) => {
        this.setState({ loadingUpload: false });
        console.log("err:", err);

        toast("Impossible de charger votre image", {
          type: "error",
          position: "top-center",
        });
      });
  }

  onRequestCreate() {
    const { atworkType, atworkUrl } = this.state;

    let bodyParams = {
      type: atworkType,
      url: atworkUrl,
    };

    const { addAtwork, titleId } = this.props;
    if (addAtwork) addAtwork(titleId, bodyParams);
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  render() {
    const {
      loading,
      mediaCategory = "TV_DESKTOP",
      title = "Ajouter une image",
    } = this.props;
    const { loadingUpload, atworkUrl, isUploaded } = this.state;

    return (
      <Modal
        title={title}
        buttons={[
          {
            loading,
            content: "Enregistrer",
            type: "primary",
            disabled: loadingUpload || !isUploaded,
            onClick: () => this.onRequestCreate(),
          },
          {
            loading,
            content: "Fermer",
            disabled: loadingUpload,
            onClick: () => this.onRequestClose(),
          },
        ]}
      >
        <div className="space-y-3 py-5 px-5">
          {loadingUpload && <ProgressIndeterminate />}
          {mediaCategory === "TV_DESKTOP" && (
            <Select
              placeholder="Sélectionner l'emplacement"
              onValueChange={(atworkType) => this.setState({ atworkType })}
            >
              <SelectItem value="TITLE_ALPHA">Logo du titre</SelectItem>
              <SelectItem value="DESKTOP_TITLE">Catalogue</SelectItem>
              <SelectItem value="DESKTOP_COVER">Couverture (large)</SelectItem>
            </Select>
          )}
          {mediaCategory === "MOBILE" && (
            <Select
              placeholder="Sélectionner l'emplacement"
              onValueChange={(atworkType) => this.setState({ atworkType })}
            >
              <SelectItem value="TITLE_ALPHA">Logo du titre</SelectItem>
              <SelectItem value="MOBILE_TITLE">Catalogue</SelectItem>
              <SelectItem value="MOBILE_COVER">Couverture (large)</SelectItem>
            </Select>
          )}
          <hr className="my-5" />
          <div className="flex space-x-5">
            <div className="flex-1">
              <a
                href="javascript:void(0);"
                onClick={() => {
                  let fileInput = document.getElementById("file-upload");
                  if (fileInput) fileInput.click();
                }}
              >
                <div className="h-[12rem] border-dashed rounded border-2 my-5 flex flex-col justify-center items-center">
                  <h1>Cliquez pour ajouter votre fichier</h1>
                </div>
              </a>
            </div>

            {isUploaded && (
              <div className="w-1/3">
                <img
                  alt={"preview"}
                  src={atworkUrl}
                  className="w-full object-contain"
                />
              </div>
            )}
          </div>

          <input
            className="hidden"
            id={"file-upload"}
            type={"file"}
            accept={"image/png,image/jpeg,image/pjpeg"}
            onChange={this.onFileChange}
          />
        </div>
      </Modal>
    );
  }
}

export default ModalCreate;
