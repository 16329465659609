const TextFields = ({
  label = "Label",
  placeholder = "Placeholder",
  nameOrId,
  onChange,
  ...props
}) => {
  return (
    <div className="flex flex-col space-y-3">
      <label class="text-sm text-gray-500" for={nameOrId}>
        {label}
      </label>
      <textarea
        {...props}
        id={nameOrId}
        name={nameOrId}
        placeholder={placeholder}
        rows="6"
        class="rounded-lg p-5 ring-1 ring-gray-200 focus:outline-gray-200 w-full text-sm text-gray-700"
        onChange={(evt) => {
          let value = evt.target.value;
          if (onChange) onChange(value);
        }}
      />
    </div>
  );
};
export default TextFields;
