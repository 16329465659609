import moment from "moment";
import React, { Component } from "react";
import { Badge, Button, Card } from "@tremor/react";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import TitleMenu from "./sidemenu";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { maturityLevelTexts } from "../../constants/maturityLevels";

class PageSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_type: "today", // today
      rows_limit: null,
      filter_date: null,
      filter_date2: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getTitle, match } = this.props;
    if (getTitle) getTitle(match.params.id, {});
  }

  render() {
    const { categories, title, match, history, loading } = this.props;
    const titleId = match.params.id;

    return (
      <div>
        <PageHeader selectedIndex={1} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} />
          <PageLayout title={"Details de votre titre"}>
            {title && (
              <div className="md:w-[60%]">
                <Card decoration="top" decorationColor="indigo">
                  <h1 className="text-2xl font-bold">{title.name}</h1>
                  <div className="divide-y border-t border-gray-200 my-5 pt-3">
                    <div className="flex justify-between py-3">
                      <h1>Titre</h1>
                      <h1 className="text-gray-700 text-right">
                        {title.title}
                      </h1>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Année de sortie</h1>
                      <h1 className="text-gray-700 text-right">
                        {title.releaseYear}
                      </h1>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Type</h1>
                      <h1 className="text-gray-700 text-right">
                        {title.contentType === "episodic" && "Série"}
                        {title.contentType === "movie" && "Court-Métrage"}
                        {title.contentType === "movie_long" && "Long-Metrage"}
                        {title.contentType === "show" && "Court-Metrage"}
                      </h1>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Description / Picth</h1>
                      <div className="w-2/3">
                        <h1 className="text-gray-700 text-right">
                          {title.shortDescription}
                        </h1>
                      </div>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Synopsis</h1>
                      <div className="w-2/3">
                        <h1 className="text-gray-700 text-right">
                          {title.longDescription}
                        </h1>
                      </div>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Classement de maturité</h1>
                      <h1 className="text-gray-700 text-right">
                        {title.maturityLevel &&
                          maturityLevelTexts[title.maturityLevel]}
                      </h1>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Contenu Original</h1>
                      <h1 className="text-gray-700 text-right">
                        {title.isOriginal ? "OUI" : "NON"}
                      </h1>
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Etat de la publication</h1>
                      {title.isPublished && (
                        <Badge color="emerald">Publié</Badge>
                      )}
                      {!title.isPublished && (
                        <Badge color="gray">Non publié</Badge>
                      )}
                    </div>
                    <div className="flex justify-between py-3">
                      <h1>Date de création</h1>
                      <h1 className="text-gray-700 text-right">
                        {moment(title.createdAt).format("DD/MM HH:mm")}
                      </h1>
                    </div>
                  </div>

                  <div className="">
                    <Button
                      variant="secondary"
                      icon={PencilSquareIcon}
                      iconPosition="right"
                      size="xs"
                      onClick={() => {
                        history.push(`/titles/${titleId}/edit`);
                      }}
                    >
                      Modifier les informations
                    </Button>
                  </div>
                </Card>
              </div>
            )}
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageSingle;
