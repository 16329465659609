import React, { Component } from "react";
import {
  Button,
  Card,
  Select,
  SelectItem,
  MultiSelect,
  MultiSelectItem,
} from "@tremor/react";
import {
  VideoCameraIcon,
  PlusCircleIcon,
  ListBulletIcon,
  PhotoIcon,
  SignalIcon,
  PencilSquareIcon,
  FolderIcon,
  BookmarkIcon,
  EyeIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import SideMenu from "../../components/sidebar/menu";
import { toast } from "react-toastify";
import TitleMenu from "./sidemenu";

class PageAvailability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maturityLevel: "all",
      maturityBoard: "",
      availabilityCountriesIds: ["CD"],
      titlePublished: "notPublished",
      titleId: null,
      lastEditedId: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchData(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { title } = this.props;

    if (title) {
      if (title.id !== prevState.titleId) {
        this.setState({
          maturityLevel: title.maturityLevel || "all",
          maturityBoard: title.maturityBoard,
          availabilityCountriesIds: title.availabilityCountriesIds || ["CD"],
          titlePublished: title.isPublished ? "isPublished" : "notPublished",
          titleId: title.id,
        });
      }
    }
  }

  componentWillUnmount() {}

  fetchData() {
    const { match, getTitle } = this.props;
    if (getTitle) getTitle(match.params.id);
  }

  onSubmitForm() {
    const { availabilityCountriesIds, maturityLevel, titlePublished } =
      this.state;
    if (availabilityCountriesIds.length === 0) {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      maturityLevel,
      availabilityCountriesIds,
      isPublished: titlePublished === "isPublished",
    };

    const { publishTitle, match } = this.props;
    if (publishTitle) publishTitle(match.params.id, bodyParams);
  }

  render() {
    const {
      maturityLevel,
      maturityBoard,
      availabilityCountriesIds,
      titlePublished,
    } = this.state;
    const { title, match, loading } = this.props;
    const titleId = match.params.id;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <TitleMenu title={title} selectedMenuIndex={6} />
          <PageLayout title={"Disponibilité de votre titre"}>
            <Card>
              {title && (
                <div className="py-10 space-y-3 md:w-[40rem]">
                  <Select
                    icon={EyeIcon}
                    value={titlePublished}
                    placeholder="Etat de la publication"
                    onValueChange={(titlePublished) => {
                      this.setState({ titlePublished });
                    }}
                  >
                    <SelectItem value="isPublished">Publiée</SelectItem>
                    <SelectItem value="notPublished">Non publiée</SelectItem>
                  </Select>

                  <Select
                    icon={EyeIcon}
                    value={maturityLevel}
                    placeholder="Classement de maturité"
                    onValueChange={(maturityLevel) => {
                      this.setState({ maturityLevel });
                    }}
                  >
                    <SelectItem value="all">
                      Recommandé pour tous les publics
                    </SelectItem>
                    <SelectItem value="7+">
                      Recommandé pour les 7 ans et plus
                    </SelectItem>
                    <SelectItem value="10+">
                      Recommandé pour les 10 ans et plus
                    </SelectItem>
                    <SelectItem value="13+">
                      Recommandé pour les 13 ans et plus
                    </SelectItem>
                    <SelectItem value="16+">
                      Recommandé pour les 16 ans et plus
                    </SelectItem>
                    <SelectItem value="18+">
                      Recommandé pour les 18 ans et plus
                    </SelectItem>
                  </Select>

                  <MultiSelect
                    icon={BookmarkIcon}
                    value={availabilityCountriesIds}
                    placeholder="Régions de disponibilité"
                    onValueChange={(availabilityCountriesIds) => {
                      this.setState({ availabilityCountriesIds });
                    }}
                  >
                    <MultiSelectItem value="CD">
                      Congo - Kinshasa
                    </MultiSelectItem>
                  </MultiSelect>

                  <hr />
                  <Button
                    icon={ArrowRightIcon}
                    iconPosition="right"
                    onClick={() => this.onSubmitForm()}
                  >
                    Enregistrer les informations
                  </Button>
                </div>
              )}
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageAvailability;
