/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, SelectItem, TextInput } from "@tremor/react";
import { DocumentIcon } from "@heroicons/react/24/outline";
import { editSeason } from "../../actions/season";
import Modal from "../../components/modal";

class SeasonEdit extends Component {
  constructor(props) {
    super(props);

    const { season } = props;
    this.state = {
      seasonName: season.name,
      shortName: season.shortName,
      seasonPublished: season.isPublished ? "isPublished" : "notPublished",
    };
  }

  onRequestCreate() {
    const { seasonName, shortName, seasonPublished } = this.state;

    let bodyParams = {
      name: seasonName,
      shortName: shortName,
      isPublished: seasonPublished === "isPublished",
      hiddenEpisodeNumbers: false,
    };

    const { editSeason, season } = this.props;
    if (editSeason) editSeason(season.id, bodyParams);
  }

  onRequestClose() {
    const { onRequestClose } = this.props;
    if (onRequestClose) onRequestClose();
  }

  render() {
    const { loading, season, title = "Modifier une saison" } = this.props;

    return (
      <Modal
        title={title}
        buttons={[
          {
            loading,
            content: "Enregistrer",
            type: "primary",
            onClick: () => this.onRequestCreate(),
          },
          {
            content: "Fermer",
            disabled: loading,
            onClick: () => this.onRequestClose(),
          },
        ]}
      >
        <div className="space-y-3 py-5 px-5">
          <div>
            <span className="text-sm text-gray-500">Etat de publication</span>
            <Select
              defaultValue={season.isPublished ? "isPublished" : "notPublished"}
              placeholder="Sélectionner l'emplacement"
              onValueChange={(seasonPublished) =>
                this.setState({ seasonPublished })
              }
            >
              <SelectItem value="isPublished">Rendre public</SelectItem>
              <SelectItem value="notPublished">Ne pas publier</SelectItem>
            </Select>
          </div>
          <div>
            <span className="text-sm text-gray-500">Titre de la saison</span>
            <TextInput
              icon={DocumentIcon}
              placeholder="Saison 1"
              defaultValue={season.name}
              onChange={(evt) => {
                let seasonName = evt.target.value;
                this.setState({ seasonName });
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingEdit,
});

const mapDispatchToProps = { editSeason };

export default connect(mapStateToProps, mapDispatchToProps)(SeasonEdit);
