import { PlayIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button } from "@tremor/react";
import React from "react";

export default function Item({
  posterAlt,
  posterImage,
  title,
  duration,
  createdAt,
  onRequestDelete,
}) {
  return (
    <div className="shadow">
      <div className="h-[10rem] relative">
        <img
          alt={posterAlt}
          src={posterImage}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center">
          <div className="w-12 h-12 rounded-full bg-white flex flex-col justify-center items-center">
            <PlayIcon className="w-5 h-5 text-black" />
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="py-3">
          <h1 className="text-md font-semibold">{title}</h1>
          <h1 className="text-sm text-gray-500">{duration}</h1>
          <h1 className="text-sm text-gray-500">Ajouté le : {createdAt}</h1>
        </div>

        <div className="flex space-x-3">
          <Button
            size="xs"
            variant="light"
            color={"red"}
            icon={TrashIcon}
            onClick={() => {
              if (onRequestDelete) onRequestDelete();
            }}
          >
            Supprimer
          </Button>
        </div>
      </div>
    </div>
  );
}
