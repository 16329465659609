import { connect } from "react-redux";
import { createCategory } from "../../actions/category";
import component from "../../content/category/create";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
  lastCreatedId: state.entities.lastCreatedId,
});

const mapDispatchToProps = { createCategory };

export default connect(mapStateToProps, mapDispatchToProps)(component);
