/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Logo from "../../../assets/img/logos/logo-main.png";

class SidebarBase extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { accountId } = this.props;
    if (!accountId) accountId = localStorage.getItem("accounts__current_id");
    return null;
    return (
      <>
        {/* Component Start */}
        <div className="hidden w-0 md:w-[300px] lg:flex lg:flex-col items-center h-full overflow-hidden text-gray-700 bg-[#0d0735]">
          <a
            className="flex items-center justify-center w-full px-3 my-10 mx-3"
            href="#"
          >
            <img src={Logo} className="h-20" alt="Logo header" />
          </a>
          <div className="w-full px-5">
            <div className="flex flex-col items-center w-full mt-3">
              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}`}
              >
                <svg
                  className="w-6 h-6 stroke-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
                <span className="ml-4 text-sm font-medium">Dashboard</span>
              </a>

              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/projects`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 stroke-gray-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>

                <span className="ml-4 text-sm font-medium">Projets</span>
              </a>
              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/persons`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 stroke-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <span className="ml-4 text-sm font-medium">Repertoire</span>
              </a>

              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/reports`}
              >
                <svg
                  className="w-6 h-6 stroke-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
                <span className="ml-4 text-sm font-medium">Rapports</span>
              </a>
            </div>
            <div className="flex flex-col items-center w-full mt-2 border-t border-gray-400">
              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/admin-users`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>
                <span className="ml-4 text-sm font-medium">Accèss</span>
              </a>
              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/users`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 stroke-gray-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>

                <span className="ml-4 text-sm font-medium">Utilisateurs</span>
              </a>
              <a
                className="flex items-center w-full h-12 px-5 mt-2 rounded hover:bg-white hover:text-[#0d0735] text-white"
                href={`/dashboard/${accountId}/settings`}
              >
                <svg
                  className="w-6 h-6 stroke-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                  />
                </svg>
                <span className="ml-4 text-sm font-medium">Paramètres</span>
              </a>
            </div>
          </div>
          <a
            className="flex items-center justify-center w-full h-16 mt-auto bg-gray-200 hover:bg-blue-500 hover:text-[#0d0735] text-white"
            href="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
              />
            </svg>

            <span className="ml-4 text-sm font-medium">Deconnexion</span>
          </a>
        </div>
        {/* Component End  */}
      </>
    );
  }
}

export default SidebarBase;
