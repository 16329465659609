import { connect } from "react-redux";
import component from "../../content/dashboard/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.report.loadingStats,
  statistics: [],
  transactions: state.report.transactions || [],
  reportButtons: state.report.reportButtons || [],
});

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(component);
