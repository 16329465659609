import moment from "moment";
import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Badge,
  Button,
} from "@tremor/react";
import EmptyView from "../../components/empty/large";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import {
  MapPinIcon,
  PlusCircleIcon,
  ListBulletIcon,
  ChevronRightIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

class PageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_type: "today", // today
      rows_limit: null,
      filter_date: null,
      filter_date2: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchDataIfNeeded(), 500);
  }

  componentWillUnmount() {}

  fetchDataIfNeeded() {
    const { getCategories } = this.props;
    if (getCategories) getCategories({});
  }

  render() {
    const { categories, loading } = this.props;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            menuItems={[
              {
                text: "Catégories",
                title: "Categories",
                href: "/categories",
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/categories/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Categories"}>
            <Table className="mt-5">
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Titre</TableHeaderCell>
                  <TableHeaderCell>Epinglée à l'accueil</TableHeaderCell>
                  <TableHeaderCell>Date création</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <Text>
                        {item.isPined && "OUI"}
                        {!item.isPined && "---"}
                      </Text>
                    </TableCell>
                    <TableCell>
                      <Text>
                        {moment(item.createdAt).format("DD/MM HH:mm")}
                      </Text>
                    </TableCell>
                    <TableCell>
                      {item.isPublished && (
                        <Badge color="emerald" icon={ChevronRightIcon}>
                          Publié
                        </Badge>
                      )}
                      {!item.isPublished && (
                        <Badge color="gray" icon={ChevronRightIcon}>
                          Non publié
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="secondary"
                        icon={ArrowRightIcon}
                        iconPosition="right"
                        size="xs"
                        onClick={() => {
                          this.props.history.push(
                            `/categories/edit/${item.id}`
                          );
                        }}
                      >
                        Modifier
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {Array.from(categories).length === 0 && (
              <div className="my-5">
                <EmptyView
                  title={"Aucune donnée à afficher ici"}
                  description={
                    "Données insuffisantes pour afficher les categories"
                  }
                />
              </div>
            )}
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageList;
