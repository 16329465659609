import { connect } from "react-redux";
import {
  getTitleById,
  getEpisodesById,
  getSeasonsById,
} from "../../selectors/TitleSelectors";
import { getTitle } from "../../actions/title";
import { getEpisodes } from "../../actions/episode";
import { getSeasons } from "../../actions/season";
import component from "../../content/episode/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  loadingDelete: state.entities.loadingDelete,
  title: getTitleById(state, ownProps.match.params.id),
  seasons: getSeasonsById(state, ownProps.match.params.id) || [],
  episodes: getEpisodesById(state, ownProps.match.params.id) || [],
});

const mapDispatchToProps = { getTitle, getEpisodes, getSeasons };

export default connect(mapStateToProps, mapDispatchToProps)(component);
