import { connect } from "react-redux";
import { createOffer } from "../../actions/offer";
import component from "../../content/offers/create";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
});

const mapDispatchToProps = { createOffer };

export default connect(mapStateToProps, mapDispatchToProps)(component);
