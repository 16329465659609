export function captureVideoFrame(video, format, quality) {
  if (typeof video === "string") {
    video = document.getElementById(video);
  }

  format = format || "jpeg";
  quality = quality || 0.92;

  if (!video || (format !== "png" && format !== "jpeg")) {
    return false;
  }

  var canvas = document.createElement("CANVAS");

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext("2d").drawImage(video, 0, 0);

  var dataUri = canvas.toDataURL("image/" + format, quality);
  var data = dataUri.split(",")[1];
  var mimeType = dataUri.split(";")[0].slice(5);

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
}

export const getObjectURL = (file, revoke) => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    let preview = window.URL.createObjectURL(file);
    // remove reference
    if (revoke) {
      window.URL.revokeObjectURL(preview);
    }
    setTimeout(() => {
      resolve(preview);
    }, 100);
  });
};

export const getVideoMetadata = (videoFile) => {
  return new Promise((resolve, reject) => {
    if (videoFile) {
      if (videoFile.type.match("video")) {
        getObjectURL(videoFile).then((url) => {
          let video = document.createElement("video");
          video.addEventListener("loadeddata", function () {
            let metadata = {
              name: videoFile.name,
              type: videoFile.type,
              duration: video.duration,
              videoHeight: video.videoHeight,
              videoWidth: video.videoWidth,
            };
            window.URL.revokeObjectURL(url);
            resolve(metadata);
          });
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        });
      }
    } else {
      alert("reject");
      reject(0);
    }
  });
};
