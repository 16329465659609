import React, { Component } from "react";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (e) => {
    const { onChange } = this.props;

    let value = e.target.value;
    if (onChange) onChange(value);
  };

  render() {
    const {
      prefix,
      label,
      type = "text",
      palceholder = "palceholder",
    } = this.props;
    return (
      <div>
        {label && (
          <label
            htmlFor="input_0"
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <div className="mt-1 relative rounded-md shadow-sm">
          {prefix && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
          )}
          <input
            type={type}
            name="input_0"
            id="input_0"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 border-gray-300 rounded-md text-sm font-medium"
            placeholder={palceholder}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

export default DatePicker;
