import { connect } from "react-redux";
import { getTitles } from "../../actions/title";
import component from "../../content/titles/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  titles: state.entities.titles || [],
});

const mapDispatchToProps = { getTitles };

export default connect(mapStateToProps, mapDispatchToProps)(component);
