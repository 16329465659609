import { connect } from "react-redux";
import { getTitleById, getTrailersById } from "../../selectors/TitleSelectors";
import { getTrailers, deleteTrailer } from "../../actions/trailer";
import { getTitle } from "../../actions/title";
import component from "../../content/trailer/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  loadingDelete: state.entities.loadingDelete,
  trailers: getTrailersById(state, ownProps.match.params.id) || [],
  title: getTitleById(state, ownProps.match.params.id),
});

const mapDispatchToProps = { getTitle, getTrailers, deleteTrailer };

export default connect(mapStateToProps, mapDispatchToProps)(component);
