import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Card,
  TextInput,
  Select,
  SelectItem,
  MultiSelect,
  MultiSelectItem,
} from "@tremor/react";
import {
  ClipboardIcon,
  BookmarkIcon,
  PlusCircleIcon,
  ListBulletIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import SideMenu from "../../components/sidebar/menu";
import { toast } from "react-toastify";
import TextFields from "../../components/form/textfields";

class PageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      shortDescription: "",
      longDescription: "",
      releaseYear: "",
      primaryCategoryId: "",
      categoryIds: [],
      languagesSubtitles: ["fr"],
      languagesAudios: ["fr"],
      contentType: "movie",
      isOriginal: "notOriginal",
      lastCreatedId: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchData(), 500);
  }

  componentWillUnmount() {}

  fetchData() {
    const { getCategories } = this.props;
    if (getCategories) getCategories({});
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, lastCreatedId } = this.props;
    if (lastCreatedId) {
      if (lastCreatedId !== prevState.lastCreatedId) {
        window.location.replace("/titles");
      }
    }
  }

  onSubmitForm() {
    const {
      title,
      shortDescription,
      longDescription,
      contentType,
      isOriginal,
      releaseYear,
      languagesAudios,
      languagesSubtitles,
      categoryIds,
      primaryCategoryId,
    } = this.state;
    if (
      title === "" ||
      contentType === "" ||
      shortDescription === "" ||
      primaryCategoryId === "" ||
      releaseYear === ""
    ) {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      title,
      contentType,
      primaryCategoryId,
      categoryIds,
      shortDescription,
      longDescription,
      languagesAudios,
      languagesSubtitles,
      isOriginal: isOriginal === "isOriginal",
      isPublished: false,
    };

    const { createTitle } = this.props;
    if (createTitle) createTitle(bodyParams);
  }

  render() {
    const { categories, loading } = this.props;
    const { categoryIds } = this.state;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            selectedMenuIndex={1}
            menuItems={[
              {
                text: "Titres",
                title: "Titres",
                href: "/titles",
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/titles/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Créer un titre"}>
            <Card>
              <div className="py-10 space-y-3 md:w-[40rem]">
                <Select
                  defaultValue="movie"
                  icon={BookmarkIcon}
                  placeholder="Format"
                  onValueChange={(contentType) => {
                    this.setState({ contentType });
                  }}
                >
                  <SelectItem value="movie">Court-Metrage</SelectItem>
                  <SelectItem value="movie_long">Long-Metrage</SelectItem>
                  <SelectItem value="episodic">Série</SelectItem>
                </Select>

                <Select
                  icon={BookmarkIcon}
                  placeholder="Catégorie principale"
                  onValueChange={(primaryCategoryId) => {
                    this.setState({
                      primaryCategoryId,
                      categoryIds: [primaryCategoryId],
                    });
                  }}
                >
                  {categories.map((item, x) => (
                    <SelectItem key={x} value={item.id}>
                      {item.name}
                    </SelectItem>
                  ))}
                </Select>

                <MultiSelect
                  icon={BookmarkIcon}
                  value={categoryIds}
                  placeholder="Catégories sécondaires"
                  onValueChange={(categoryIds) => {
                    console.log(categoryIds);
                    this.setState({ categoryIds });
                  }}
                >
                  {categories.map((item, x) => (
                    <MultiSelectItem key={x} value={item.id}>
                      {item.name}
                    </MultiSelectItem>
                  ))}
                </MultiSelect>

                <TextInput
                  icon={ClipboardIcon}
                  placeholder="Titre"
                  onChange={(evt) => {
                    let title = evt.target.value;
                    this.setState({ title });
                  }}
                />

                <Select
                  icon={BookmarkIcon}
                  placeholder="Contenu original ?"
                  onValueChange={(isOriginal) => {
                    this.setState({ isOriginal });
                  }}
                >
                  <SelectItem value="isOriginal">OUI</SelectItem>
                  <SelectItem value="notOriginal">NON</SelectItem>
                </Select>

                <TextInput
                  icon={CalendarDaysIcon}
                  placeholder="Année de sortie"
                  onChange={(evt) => {
                    let releaseYear = evt.target.value;
                    this.setState({ releaseYear });
                  }}
                />

                <TextFields
                  label="Accroche courte"
                  nameOrId={"shortDescription"}
                  placeholder="Entrez votre accroche ici..."
                  onChange={(shortDescription) => {
                    this.setState({ shortDescription });
                  }}
                />

                <TextFields
                  label="Synospis du titre"
                  nameOrId={"longDescription"}
                  placeholder="Entrez votre synospis ici..."
                  onChange={(longDescription) => {
                    this.setState({ longDescription });
                  }}
                />

                <hr />
                <Button
                  loading={loading}
                  icon={ArrowRightIcon}
                  iconPosition="right"
                  onClick={() => this.onSubmitForm()}
                >
                  Créer le titre
                </Button>
              </div>
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageCreate;
