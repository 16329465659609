import { toast } from "react-toastify";
import {
  titlesList,
  titleCreate,
  titleEdit,
  titleSingle,
  titlePublish,
} from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getTitles =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { project } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    titlesList(params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { titles } = response;

        if (titles) {
          dispatch(setKeyValue("titles", titles));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossibler de récupérer les titles", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createTitle =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    titleCreate(params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { title } = response;

        if (title) {
          const lastCreatedId = `item-${new Date().getTime()}`;
          toast("Titre crée", { position: "top-center" });
          dispatch(setKeyValue("lastCreatedId", lastCreatedId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossibler de créer le titre", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const getTitle =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    titleSingle(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { title } = response;

        if (title) {
          let titlesById = { ...entities.titlesById };
          titlesById[titleId] = title;
          dispatch(setKeyValue("titlesById", titlesById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossibler de récupérer les details", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editTitle =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    titleEdit(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { title } = response;

        if (title) {
          toast("Titre modifié", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossibler de modifier le titre", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const publishTitle =
  (titleId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    titlePublish(titleId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { title } = response;

        if (title) {
          toast("Modifications enregistrées", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossibler de modifier le titre", {
          position: "top-center",
          type: "error",
        });
      });
  };
