import { connect } from "react-redux";
import { getOffers } from "../../actions/offer";
import component from "../../content/offers/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  offers: state.entities.offers || [],
});

const mapDispatchToProps = { getOffers };

export default connect(mapStateToProps, mapDispatchToProps)(component);
