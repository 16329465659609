import React from "react";

export default function EmptyView({ title, description }) {
  return (
    <div className="flex flex-col justify-center items-center py-32">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-28 w-28 stroke-gray-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <div className="my-10 text-center">
        <h1 className="text-2xl leading-tight font-bold">{title}</h1>
        <h4 className="text-ld leading-tight font-medium mt-5">
          {description}
        </h4>
      </div>
    </div>
  );
}
