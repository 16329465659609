import { toast } from "react-toastify";
import {
  categoriesList,
  categoryCreate,
  categoryEdit,
  categorySingle,
} from "../api/titleService";
import { getCookie } from "../helpers/cookies";

export const setKeyValue = (propKey, propValue) => ({
  type: "ENTITIES_SET_VALUE",
  propKey,
  propValue,
});

export const getCategories =
  (projectId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { project } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    categoriesList(projectId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { categories } = response;

        if (categories) {
          dispatch(setKeyValue("categories", categories));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossibler de récupérer les categories", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const createCategory =
  (params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    categoryCreate(params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { category } = response;

        if (category) {
          const lastCreatedId = `item-${new Date().getTime()}`;
          toast("Catégory créée", { position: "top-center" });
          dispatch(setKeyValue("lastCreatedId", lastCreatedId));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossibler de créer la catégorie", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const getCategory =
  (categoryId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const { entities } = state;

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loading", true));
    categorySingle(categoryId, params)
      .then((result) => {
        dispatch(setKeyValue("loading", false));

        let response = result.data;
        const { category } = response;

        if (category) {
          let categoriesById = { ...entities.categoriesById };
          categoriesById[categoryId] = category;
          dispatch(setKeyValue("categoriesById", categoriesById));
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loading", false));
        toast("Impossibler de récupérer les details", {
          position: "top-center",
          type: "error",
        });
      });
  };

export const editCategory =
  (categoryId, params = {}) =>
  (dispatch, getState) => {
    const state = getState();

    params["access_token"] = getCookie("access_token");

    dispatch(setKeyValue("loadingCreate", true));
    categoryEdit(categoryId, params)
      .then((result) => {
        dispatch(setKeyValue("loadingCreate", false));

        let response = result.data;
        const { category } = response;

        if (category) {
          toast("Catégorie modifié", {
            position: "top-center",
            type: "success",
          });
        } else {
          throw new Error("error");
        }
      })
      .catch((err) => {
        dispatch(setKeyValue("loadingCreate", false));
        toast("Impossibler de modifier la catégorie", {
          position: "top-center",
          type: "error",
        });
      });
  };
