import { connect } from "react-redux";
import { addMovie } from "../../actions/movie";
import { getTitle } from "../../actions/title";
import { getTitleById } from "../../selectors/TitleSelectors";
import component from "../../content/movie/create";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loadingCreate,
  title: getTitleById(state, ownProps.match.params.id),
});

const mapDispatchToProps = { addMovie, getTitle };

export default connect(mapStateToProps, mapDispatchToProps)(component);
