import React, { Component } from "react";
import { Button, Card, TextInput, Select, SelectItem } from "@tremor/react";
import {
  ClipboardIcon,
  BookmarkIcon,
  PlusCircleIcon,
  ListBulletIcon,
  ArrowRightIcon,
  EyeIcon
} from "@heroicons/react/24/outline";
import PageHeader from "../../components/header/admin";
import PageLayout from "../../components/layout/page";
import ProgressIndeterminate from "../../components/progress/indeterminate";
import SideMenu from "../../components/sidebar/menu";
import { toast } from "react-toastify";

class PageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: "",
      categoryDescription: "",
      categoryPined: "notPined",
      categoryPublished: "notPublished",
      categoryId: null,
      lastEditedId: null,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchData(), 500);
  }

  componentDidUpdate(prevProps, prevState) {
    const { history, lastEditedId, category } = this.props;
    if (lastEditedId) {
      if (lastEditedId !== prevState.categoryId) {
        // history.push("/categories");
      }
    }

    if (category) {
      if (category.id !== prevState.categoryId) {
        this.setState({
          categoryName: category.name,
          categoryDescription: category.description,
          categoryPined: category.isPined ? "isPined" : "notPined",
          categoryPublished: category.isPublished
            ? "isPublished"
            : "notPublished",
          categoryId: category.id,
        });
      }
    }
  }

  componentWillUnmount() {}

  fetchData() {
    const { match, getCategory } = this.props;
    if (getCategory) getCategory(match.params.id);
  }

  onSubmitForm() {
    const {
      categoryName,
      categoryDescription,
      categoryPined,
      categoryPublished,
    } = this.state;
    if (categoryName === "" || categoryDescription === "") {
      toast("Veuillez remplir tous les champs !");
      return;
    }

    let bodyParams = {
      name: categoryName,
      description: categoryDescription,
      isPined: categoryPined === "isPined" ? true : false,
      isPublished: categoryPublished === "isPublished" ? true : false,
    };

    const { editCategory, match } = this.props;
    if (editCategory) editCategory(match.params.id, bodyParams);
  }

  render() {
    const { category, loading } = this.props;
    const {
      categoryName,
      categoryDescription,
      categoryPined,
      categoryPublished,
    } = this.state;

    return (
      <div>
        <PageHeader selectedIndex={2} />
        <div className="flex justify-center w-screen h-screen space-x-2">
          <SideMenu
            selectedMenuIndex={0}
            menuItems={[
              {
                text: "Catégories",
                title: "Categories",
                href: "/categories",
                icon: <ListBulletIcon className="w-4 h-4" />,
              },
              {
                text: "Ajouter",
                title: "Ajouter",
                href: "/categories/create",
                icon: <PlusCircleIcon className="w-4 h-4" />,
              },
            ]}
          />
          <PageLayout title={"Modifier une catégorie"}>
            <Card>
              {category && (
                <div className="py-10 space-y-3 md:w-[40rem]">
                  <TextInput
                    icon={ClipboardIcon}
                    value={categoryName}
                    placeholder="Nom de la catégorie"
                    onChange={(evt) => {
                      let categoryName = evt.target.value;
                      this.setState({ categoryName });
                    }}
                  />
                  <TextInput
                    icon={ClipboardIcon}
                    value={categoryDescription}
                    placeholder="Description de la catégorie"
                    onChange={(evt) => {
                      let categoryDescription = evt.target.value;
                      this.setState({ categoryDescription });
                    }}
                  />

                  <Select
                    icon={BookmarkIcon}
                    value={categoryPined}
                    placeholder="Epingler à la page d'accueil ?"
                    onValueChange={(categoryPined) => {
                      this.setState({ categoryPined });
                    }}
                  >
                    <SelectItem value="isPined">Epinglé à l'accueil</SelectItem>
                    <SelectItem value="notPined">Non Epinglé à l'accueil</SelectItem>
                  </Select>

                  <Select
                    icon={EyeIcon}
                    value={categoryPublished}
                    placeholder="Etat de la publication"
                    onValueChange={(categoryPublished) => {
                      this.setState({ categoryPublished });
                    }}
                  >
                    <SelectItem value="isPublished">Publiée</SelectItem>
                    <SelectItem value="notPublished">Non publiée</SelectItem>
                  </Select>

                  <hr />
                  <Button
                    icon={ArrowRightIcon}
                    iconPosition="right"
                    onClick={() => this.onSubmitForm()}
                  >
                    Modifier la catégorie
                  </Button>
                </div>
              )}
            </Card>
          </PageLayout>
        </div>
      </div>
    );
  }
}

export default PageEdit;
