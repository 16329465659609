import { connect } from "react-redux";
import { getCategories } from "../../actions/category";
import component from "../../content/category/index";

const mapStateToProps = (state, ownProps) => ({
  loading: state.entities.loading,
  categories: state.entities.categories || [],
});

const mapDispatchToProps = { getCategories };

export default connect(mapStateToProps, mapDispatchToProps)(component);
