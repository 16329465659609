import axios from "axios";
import { API_BASE_HOST } from "../constants/api";

const ApiContext = axios.create({
  baseURL: API_BASE_HOST,
  withCredentials: false,
  timeout: 30000,
  headers: { "Content-Type": "application/json" },
});

ApiContext.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.replace("/?logout=auto");
    }
    return Promise.reject(error.response);
  }
);

/**
 * offers
 */

export function offersList(params = {}) {
  return ApiContext.get(`offers`, { params });
}

export function offerCreate(bodyParams, params = {}) {
  return ApiContext.post(`offers`, bodyParams, { params });
}

export function offerSingle(refId, params = {}) {
  return ApiContext.get(`offers/${refId}`, { params });
}

export function offerEdit(refId, body, params = {}) {
  return ApiContext.patch(`offers/${refId}`, body, { params });
}

export function offerTitles(refId, body, params = {}) {
  return ApiContext.post(`offers/${refId}/titles`, body, { params });
}

export function planCreate(refId, body, params = {}) {
  return ApiContext.post(`offers/${refId}/plans`, body, { params });
}

export function offerPlans(refId, params = {}) {
  return ApiContext.get(`offers/${refId}/plans`, { params });
}

export function planEdit(refId, body, params = {}) {
  return ApiContext.patch(`plans/${refId}`, body, { params });
}

export function planDelete(refId, params = {}) {
  return ApiContext.delete(`plans/${refId}`, { params });
}
